import { PlayerModel } from '@shared/models/team/player/player'
import { useMyTeamStore } from './my-team-store'

const { getState, setState } = useMyTeamStore

export const toggleEditing = () => setState({ editing: !getState().editing })
export const setEditing = (editing: boolean) => setState({ editing })
export const showPlayerInfo = (player: PlayerModel) =>
  // eslint-disable-next-line no-alert
  alert(`TODO: Show info box about ${player.name}`)
