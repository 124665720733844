import { blue, green } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Shell } from './components/shell/shell'

const theme = createMuiTheme({
  palette: {
    error: {
      main: '#CF6679'
    },
    primary: {
      main: green[600]
    },
    secondary: {
      main: blue[500]
    }
  }
})

export const Layout: FC = ({ children }) => {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      localStorage.setItem('code', hash.split('=').pop() || '')
      window.location.assign(
        `${window.location.origin}${window.location.pathname}`
      )
    }
  }, [hash])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Shell>{children}</Shell>
    </ThemeProvider>
  )
}
