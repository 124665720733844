import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import { FC } from 'react'
import { useViewTeamStore } from '../store/view-team-store'

export const Matches: FC = () => {
  const matchGroups = useViewTeamStore((state) => state.matchGroups)

  return (
    <Grid container spacing={4}>
      {matchGroups.map((group, index) => (
        <Grid key={index} item xs={12}>
          <Box mb={2}>
            <Typography variant="h6">
              {group.date} {group.day}
            </Typography>
          </Box>
          <Paper>
            <Table>
              <TableBody>
                {group.matches.map((match, index2) => (
                  <TableRow key={index2}>
                    <TableCell width="15%">{match.time}</TableCell>
                    <TableCell width="35%" align="right">
                      {match.homeClub}
                    </TableCell>
                    <TableCell width="15%" align="center">
                      {match.score}
                    </TableCell>
                    <TableCell width="35%">{match.awayClub}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}
