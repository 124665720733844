import { Box } from '@material-ui/core'
import { FC, useEffect } from 'react'
import { useUser } from '@root/store/app-selectors'
import { EditTeam } from './components/edit-team/edit-team'
import { ViewTeam } from './components/view-team/view-team'
import { setEditing } from './store/my-team-actions'
import { useMyTeamStore } from './store/my-team-store'

export const MyTeam: FC = () => {
  const editing = useMyTeamStore((state) => state.editing)
  const user = useUser()

  useEffect(() => {
    if (user) {
      setEditing(!user.hasTeamSetup)
    }
  }, [user])

  return (
    <Box mt={2}>
      {!editing && <ViewTeam />}
      {editing && <EditTeam />}
    </Box>
  )
}
