import { statCategories } from '@shared/models/player-performance'

const capitalize = (str: string) =>
  `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`

export const statCategoryColumns = Object.keys(statCategories).map(
  (statCategory) => ({
    key: statCategory,
    description: capitalize(statCategory.split(/(?=[A-Z])/).join(' ')),
    shortDescription: statCategory
      .split(/(?=[A-Z])/)
      .filter((word) => word !== 'On')
      .map((word) => word.slice(0, 1))
      .slice(0, 2)
      .join('')
      .toUpperCase()
  })
)
