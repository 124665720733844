import { Grid } from '@material-ui/core'
import { FC } from 'react'
import { PlayerBackground } from '../../player-background'
import { PlayerRole } from '../../player-role'
import { selectPlayerId } from '../store/view-team-actions'
import { useSubstitutes } from '../store/view-team-selectors'

export const Substitutes: FC = () => {
  const substitutes = useSubstitutes()

  return (
    <PlayerBackground>
      <Grid container justify="center" spacing={2}>
        {substitutes.map(({ role, player }, index) => (
          <Grid key={index} item xs={3}>
            <PlayerRole
              color={player?.substituted ? 'yellow' : 'grey'}
              role={role}
              name={player?.name}
              price={player?.price}
              points={player?.points}
              click={() => player && selectPlayerId(player.id)}
            />
          </Grid>
        ))}
      </Grid>
    </PlayerBackground>
  )
}
