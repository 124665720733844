import { endpoints } from '@root/transport/http/endpoints'
import { initialState, useMatchDetailStore } from './match-detail-store'

const { setState } = useMatchDetailStore

export const resetMatchDetail = () => setState({ ...initialState })

export const fetchMatchDetail = async (id: string) => {
  setState({
    matchDetail: await endpoints.getMatchDetail.dispatch(id)
  })
}
