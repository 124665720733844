import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import { Loader } from '@root/components/loader'
import { PlayerPerformances } from '@root/components/player-performances'
import {
  fetchPlayerDetail,
  resetPlayerDetail
} from './store/player-detail-actions'
import { usePerformances } from './store/player-detail-selectors'
import { usePlayerDetailStore } from './store/player-detail-store'

export const PlayerDetail: FC = () => {
  const playerDetail = usePlayerDetailStore((state) => state.playerDetail)
  const performances = usePerformances()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    fetchPlayerDetail(id)

    return resetPlayerDetail
  }, [id])

  if (!playerDetail) {
    return <Loader />
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {playerDetail.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Typography variant="h6">
                Positions: {playerDetail.positions.join(', ')}
              </Typography>
              <Typography variant="h6">
                Total points: {playerDetail.totalPoints}
              </Typography>
              <Typography variant="h6">
                Price: € {playerDetail.price}M
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <PlayerPerformances
              playerPerformances={performances}
              againstClubs
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
