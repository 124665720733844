import { getRoleByPosition } from '@shared/helpers/players'
import { FormationModel } from '@shared/models/team/formation'
import { AvailablePlayerModel } from '@shared/models/team/player/available-player'
import { PositionModel } from '@shared/models/team/player/position/position'
import { isEditing } from '@root/pages/my-team/helpers/editing'
import { getUserId } from '@root/pages/my-team/helpers/user-id'
import {
  setEditing,
  toggleEditing
} from '@root/pages/my-team/store/my-team-actions'
import { endpoints } from '@root/transport/http/endpoints'
import { initialEditTeamState, useEditTeamStore } from './edit-team-store'
import { getTransfersLeft } from './helpers/get-transfers-left'
import { PlayerFilterModel } from './models/player-filter'
import { ActiveDialogModel } from '../models/active-dialog'

const { getState, setState } = useEditTeamStore

const setSelectedPosition = (position: PositionModel) => {
  const { filters } = getState()
  const role = getRoleByPosition(position)

  setState({
    selectedPosition: position,
    filters:
      role === 'SUB'
        ? filters
        : {
            ...initialEditTeamState.filters,
            role
          }
  })
}

export const resetEditableTeam = () => setState({ ...initialEditTeamState })
export const fetchEditableTeam = async () => {
  if (isEditing()) {
    const {
      transferBudget,
      totalTransfers,
      wildcardsAvailable,
      wildcardActive,
      formation,
      lastRoundPlayers,
      players,
      availablePlayers,
      clubs,
      nations
    } = await endpoints.myTeam.getEditableTeam.dispatch(getUserId())
    const cachedFormation = localStorage.getItem('formation') || ''

    setState({
      viewState: 'viewing',
      transferBudget,
      totalTransfers: totalTransfers === 'Infinity' ? Infinity : totalTransfers,
      wildcardsAvailable,
      wildcardActive,
      formation:
        cachedFormation && !players.length
          ? (cachedFormation as FormationModel)
          : formation,
      lastRoundPlayers,
      players: players.length
        ? players
        : JSON.parse(localStorage.getItem('players') || '[]'),
      availablePlayers,
      clubs,
      nations
    })

    setState({
      availablePlayers: await endpoints.myTeam.getAvailablePlayers.dispatch()
    })
  }
}

export const closeDialog = () => setState({ activeDialog: 'none' })
export const setActiveDialog = (activeDialog: ActiveDialogModel) =>
  setState({ activeDialog })

export const setFormation = (formation: FormationModel) =>
  setState({ formation, players: [] })

export const selectPosition = (position: PositionModel) => {
  const { players, selectedPosition } = getState()

  if (selectedPosition) {
    const selectedPlayer = players.find(
      (player) => player.position === selectedPosition
    )
    const newSelectedPlayer = players.find(
      (player) => player.position === position
    )
    const selectedPlayerRole =
      selectedPlayer && getRoleByPosition(selectedPlayer.position)
    const newSelectedPlayerRole =
      newSelectedPlayer && getRoleByPosition(newSelectedPlayer.position)

    if (selectedPosition === position) {
      setState({
        selectedPosition: undefined,
        filters: { ...initialEditTeamState.filters }
      })
    } else if (
      selectedPlayer &&
      newSelectedPlayer &&
      ((selectedPlayerRole === 'SUB' && newSelectedPlayerRole !== 'SUB') ||
        (selectedPlayerRole !== 'SUB' && newSelectedPlayerRole === 'SUB'))
    ) {
      setState({
        selectedPosition: undefined,
        players: players.map((player) => {
          switch (player.position) {
            case selectedPlayer.position:
              return { ...player, position: newSelectedPlayer.position }
            case newSelectedPlayer.position:
              return { ...player, position: selectedPlayer.position }
            default:
              return player
          }
        })
      })
    } else {
      setSelectedPosition(position)
    }
  } else {
    setSelectedPosition(position)
  }
}

export const updateFilters = (partialFilter: Partial<PlayerFilterModel>) => {
  const { filters } = getState()

  setState({
    filters: {
      ...filters,
      ...partialFilter,
      page: partialFilter.page || 1
    }
  })
}

export const clearFilters = () =>
  setState({ filters: { ...initialEditTeamState.filters } })

export const showMoreAvailablePlayers = async () => {
  const { filters } = getState()

  setState({
    filters: { ...filters, page: filters.page + 1 }
  })
}

export const transferAvailablePlayer = async (
  availablePlayer: AvailablePlayerModel
) => {
  const {
    totalTransfers,
    wildcardActive,
    players,
    lastRoundPlayers,
    selectedPosition
  } = getState()

  if (
    getTransfersLeft(
      totalTransfers,
      wildcardActive,
      players,
      lastRoundPlayers
    ) < 0
  ) {
    setState({ activeDialog: 'notEnoughTransfers' })
  } else if (selectedPosition) {
    setState({
      selectedPosition: undefined,
      players: [
        ...players.filter((player) => player.position !== selectedPosition),
        { ...availablePlayer, position: selectedPosition }
      ]
    })
  }

  localStorage.setItem('players', JSON.stringify(getState().players))
  localStorage.setItem('formation', getState().formation)
}

export const resetTeam = async () => {
  setState({ viewState: 'resetting' })
  await endpoints.myTeam.resetTeam.dispatch(getUserId())
  toggleEditing()
}

export const useWildcard = () => {
  setState({
    wildcardsAvailable: getState().wildcardsAvailable - 1,
    wildcardActive: true
  })
}

export const saveTeam = async () => {
  const { wildcardActive, formation, players } = getState()

  setState({ viewState: 'saving' })
  await endpoints.myTeam.saveTeam.dispatch({
    userId: getUserId(),
    wildcardActive,
    formation,
    players: players.map((player) => ({
      id: player.id,
      position: player.position
    }))
  })

  setEditing(false)
}
