import { useClubDetailStore } from './club-detail-store'
import {
  PlayerPerformanceViewModel,
  StatsViewModel
} from '../../../models/player-performance-view'

export const usePlayerPerformances = (): Array<PlayerPerformanceViewModel> => {
  const clubDetail = useClubDetailStore((state) => state.clubDetail)

  if (clubDetail) {
    return clubDetail.players
      .map((playerPerformance) => ({
        ...playerPerformance,
        href: `/players/${playerPerformance.id}`,
        stats: Object.keys(playerPerformance.stats)
          .map((statCategory) => ({
            statCategory,
            value: playerPerformance.stats[statCategory] || '-'
          }))
          .reduce(
            (stats, { statCategory, value }) => ({
              ...stats,
              [statCategory]: value
            }),
            {} as StatsViewModel
          )
      }))
      .sort((a, b) => b.points - a.points)
  }

  return []
}
