import { Box, Paper, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { FC } from 'react'

const useStyles = makeStyles((theme) => ({
  field: {
    position: 'relative',
    overflow: 'hidden',
    background: green[200],
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 'calc(50% - 3px)',
      width: '100%',
      height: 3,
      background: 'white'
    }
  }
}))

interface Props {
  fieldDetails?: boolean
}

export const PlayerBackground: FC<Props> = ({ fieldDetails, children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.field}>
      {fieldDetails && (
        <>
          <Box
            position="absolute"
            top={-100}
            left="calc(50% - 175px)"
            border="3px solid white"
            borderRadius={2}
            width={350}
            height={200}
            zIndex={0}
          />
          <Box
            position="absolute"
            top="calc(50% - 100px)"
            left="calc(50% - 100px)"
            border="3px solid white"
            borderRadius="100%"
            width={200}
            height={200}
            zIndex={0}
          />
          <Box
            position="absolute"
            bottom={-100}
            left="calc(50% - 175px)"
            border="3px solid white"
            borderRadius={2}
            width={350}
            height={200}
            zIndex={0}
          />
        </>
      )}
      <Box p={2}>{children}</Box>
    </Paper>
  )
}
