import { Avatar, Box, Grid, Typography, makeStyles } from '@material-ui/core'
import { amber, green } from '@material-ui/core/colors'
import clsx from 'clsx'
import { FC } from 'react'
import { RoleModel } from '@shared/models/team/player/role/role'

const useStyles = makeStyles((theme) => ({
  selectable: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      left: -theme.spacing(),
      top: -theme.spacing(),
      width: `calc(100% + ${theme.spacing() * 2}px)`,
      height: `calc(100% + ${theme.spacing() * 2}px)`,
      content: '""',
      borderRadius: 4,
      transition: 'background 100ms'
    },
    '&:hover': {
      cursor: 'pointer',
      '&::before': {
        background: 'rgba(0, 0, 0, 0.3)'
      }
    }
  },
  selected: {
    '&::before': {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  },
  avatar: {
    width: 50,
    height: 50,
    background: 'white',
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 'bold',
    border: `3px solid ${theme.palette.grey[500]}`
  },
  avatarSub: {
    fontSize: '14px',
    borderRadius: '4px',
    width: '60px',
    height: '40px'
  },
  avatarGreen: {
    borderColor: `${green[600]} !important`
  },
  avatarYellow: {
    borderColor: `${amber[600]} !important`
  },
  playerName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%'
  }
}))

export const PlayerRole: FC<{
  role: RoleModel
  color?: 'grey' | 'green' | 'yellow'
  name?: string
  points?: number | 'not-available'
  price?: number
  unavailable?: boolean
  selected?: boolean
  click: () => void
}> = ({ color, role, name, points, price, unavailable, selected, click }) => {
  const classes = useStyles()

  return (
    <Box display="flex" justifyContent="center">
      <Box
        className={clsx(
          !unavailable && classes.selectable,
          selected && classes.selected
        )}
        onClick={click}
      >
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Avatar
              className={clsx(
                classes.avatar,
                role === 'SUB' && classes.avatarSub,
                color === 'green' && classes.avatarGreen,
                color === 'yellow' && classes.avatarYellow
              )}
            >
              {role}
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <Box
              width={120}
              minHeight={49}
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              borderRadius={2}
              bgcolor="#84ab86"
              color="white"
              px={1.5}
              zIndex={2}
            >
              <Typography
                component="div"
                variant="subtitle2"
                className={classes.playerName}
              >
                <strong>{name || 'Unassigned'}</strong>
              </Typography>
              <Typography component="div" variant="subtitle1">
                {typeof points === 'number' && <strong>{points} pts</strong>}
                {!!price && <strong>€ {price}M</strong>}
                {points === 'not-available' && !price && '-'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
