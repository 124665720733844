import { Grid } from '@material-ui/core'
import { FC } from 'react'
import { PlayerBackground } from '../../player-background'
import { PlayerRole } from '../../player-role'
import { selectPosition } from '../store/edit-team-actions'
import { useSubstitutes } from '../store/edit-team-selectors'

export const Substitutes: FC = () => {
  const substitutes = useSubstitutes()

  return (
    <PlayerBackground>
      <Grid container justify="center" spacing={2}>
        {substitutes.map(
          (
            { role, mayTransfer, unavailable, selected, player, position },
            index
          ) => (
            <Grid key={index} item xs={3}>
              <PlayerRole
                color={mayTransfer ? 'yellow' : 'grey'}
                unavailable={unavailable}
                role={role}
                name={player?.name}
                price={player?.price}
                selected={selected}
                click={() => !unavailable && selectPosition(position)}
              />
            </Grid>
          )
        )}
      </Grid>
    </PlayerBackground>
  )
}
