"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formations = void 0;
exports.formations = Object.keys({
    '3-5-2': undefined,
    '3-4-3': undefined,
    '4-4-2': undefined,
    '4-3-3': undefined,
    '4-2-3-1': undefined,
    '5-3-2': undefined,
    '5-2-3': undefined
});
