import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { FC } from 'react'
import { useUser } from '@root/store/app-selectors'
import {
  saveTeam,
  setActiveDialog,
  useWildcard
} from '../store/edit-team-actions'
import {
  useMayReset,
  useMaySave,
  useMayUseWildcard,
  useTransferBudgetLeft,
  useTransfersLeft
} from '../store/edit-team-selectors'
import { useEditTeamStore } from '../store/edit-team-store'

export const Navigation: FC = () => {
  const user = useUser()
  const [viewState, transferBudget, totalTransfers, wildcardsAvailable] =
    useEditTeamStore((state) => [
      state.viewState,
      state.transferBudget,
      state.totalTransfers,
      state.wildcardsAvailable
    ])
  const transferBudgetLeft = useTransferBudgetLeft()
  const transfersLeft = useTransfersLeft()
  const mayUseWildcard = useMayUseWildcard()
  const mayReset = useMayReset()
  const maySave = useMaySave()

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            <div>Budget</div>
            <Typography variant="h6">
              <Box component="span" color={transferBudgetLeft < 0 && red[500]}>
                € {transferBudgetLeft.toFixed(1)}M
              </Box>{' '}
              of € {transferBudget}M
            </Typography>
          </Grid>
          <Grid item xs>
            <div>Transfers</div>
            <Typography variant="h6">
              {transfersLeft === Infinity && '∞'}
              {transfersLeft !== Infinity &&
                `${transfersLeft} of ${totalTransfers}`}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="outlined"
                  disabled={!mayReset}
                  onClick={() => setActiveDialog('confirmReset')}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={!mayUseWildcard}
                  onClick={useWildcard}
                >
                  Wildcard ({wildcardsAvailable})
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={!user?.hasTeamSetup}
                  onClick={() => setActiveDialog('confirmCancel')}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!maySave || viewState === 'saving'}
                  onClick={saveTeam}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
