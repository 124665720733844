"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mayUseWildcard = exports.maySaveBaseTeam = exports.getTransferBudgetLeft = exports.getTransfersLeft = exports.getFormationPositions = void 0;
const formations_block_map_1 = require("@shared/constants/formations-block-map");
const positions_1 = require("@shared/constants/positions");
const players_1 = require("@shared/helpers/players");
const getFormationPositions = (formation) => [
    ...formations_block_map_1.formationBlockMap[formation]
        .filter((block) => block.position)
        .map((block) => {
        if (block.position) {
            return block.position;
        }
        throw new Error('The block has no position');
    }),
    ...positions_1.substitutePositions
];
exports.getFormationPositions = getFormationPositions;
const getTransfersLeft = (totalTransfers, wildcardActive, players, lastRoundPlayers) => (wildcardActive ? Infinity : totalTransfers) -
    players.filter((player) => !lastRoundPlayers.find((lastRoundPlayer) => players_1.identifyPlayer(lastRoundPlayer, player))).length;
exports.getTransfersLeft = getTransfersLeft;
const getTransferBudgetLeft = (transferBudget, players) => (transferBudget * 10 -
    players.reduce((totalPrice, player) => totalPrice + player.price * 10, 0)) /
    10;
exports.getTransferBudgetLeft = getTransferBudgetLeft;
const maySaveBaseTeam = (transferBudget, formation, players) => exports.getTransferBudgetLeft(transferBudget, players) >= 0 &&
    exports.getFormationPositions(formation).length === players.length;
exports.maySaveBaseTeam = maySaveBaseTeam;
const mayUseWildcard = (totalTransfers, availableWildcards) => totalTransfers < Infinity && availableWildcards > 0;
exports.mayUseWildcard = mayUseWildcard;
