import { RoleFilterModel } from '../models/role-filter'

const rolesRecord: Record<RoleFilterModel, undefined> = {
  Defenders: undefined,
  Midfielders: undefined,
  Attackers: undefined,
  GK: undefined,
  CB: undefined,
  LB: undefined,
  RB: undefined,
  CM: undefined,
  AM: undefined,
  LW: undefined,
  RW: undefined,
  FW: undefined
}

export const roles = Object.keys(rolesRecord)
