import { formationBlockMap } from '@shared/constants/formations-block-map'
import { substitutePositions } from '@shared/constants/positions'
import { getRoleByPosition } from '@shared/helpers/players'
import { FormationModel } from '@shared/models/team/formation'
import { AvailablePlayerModel } from '@shared/models/team/player/available-player'
import { PlayerRoleModel } from '@shared/models/team/player/role/player-role'
import { MyTeamCollection } from '@shared/transport/http/collection/my-team/my-team'
import { mockRequest } from '../../mock-request'

export const shuffle = <T>(array: Array<T>): Array<T> =>
  array.slice().sort(() => Math.random() - 0.5)

const randomBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min

const team = {
  formation: '4-2-3-1' as const,
  players: [
    {
      position: 'FW' as const,
      id: 'dea698d9',
      name: 'C. Ronaldo',
      points: 12.75,
      price: 12.5,
      roles: ['FW'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'LW' as const,
      id: '2f557579',
      name: 'L. Insigne',
      points: 24.5,
      price: 13.9,
      roles: ['LW', 'AM'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'AM' as const,
      id: 'a5296e55',
      name: 'P. Zieliński',
      points: -5.5,
      price: 11.6,
      roles: ['CM', 'AM', 'FW'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'RW' as const,
      id: 'b0f7e36c',
      name: 'F. Chiesa',
      points: 6.25,
      price: 11.1,
      roles: ['RB', 'LW', 'RW', 'LB'] as Array<PlayerRoleModel>,
      substituted: true
    },
    {
      position: 'LCM' as const,
      id: '3b8674e6',
      name: 'R. Bentancur',
      points: 10.5,
      price: 9.6,
      roles: ['CM'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'RCM' as const,
      id: 'cf007308',
      name: 'E. Elmas',
      points: 8.75,
      price: 4.8,
      roles: ['CM', 'LW'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'LB' as const,
      id: 'ee93c1a9',
      name: 'F. Bernardeschi',
      points: 12.25,
      price: 6.7,
      roles: ['LB', 'LW', 'RW'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'LCB' as const,
      id: 'e85fb9b8',
      name: 'G. Chiellini',
      points: 18.75,
      price: 6.8,
      roles: ['CB'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'RCB' as const,
      id: 'c707411f',
      name: 'K. Manolas',
      points: 2.5,
      price: 8,
      roles: ['CB'] as Array<PlayerRoleModel>,
      substituted: true
    },
    {
      position: 'RB' as const,
      id: '0ab1f153',
      name: 'J. Cuadrado',
      points: 9,
      price: 11.6,
      roles: ['RW', 'LB', 'RB'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'GK' as const,
      id: '9413b19f',
      name: 'A. Meret',
      points: 12,
      price: 7.7,
      roles: ['GK'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'SUB1' as const,
      id: '085e71a7',
      name: 'B. Drągowski',
      points: 11,
      price: 8.8,
      roles: ['GK'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'SUB2' as const,
      id: '8c725b43',
      name: 'G. Pezzella',
      points: 0,
      price: 7.7,
      roles: ['CB'] as Array<PlayerRoleModel>,
      substituted: true
    },
    {
      position: 'SUB3' as const,
      id: '9e1577aa',
      name: 'C. Biraghi',
      points: 6.25,
      price: 9.5,
      roles: ['LB'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'SUB4' as const,
      id: '4611e028',
      name: 'E. Pulgar',
      points: 8,
      price: 8.4,
      roles: ['CM'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'SUB5' as const,
      id: 'ef619b0b',
      name: 'A. Ramsey',
      points: 14.75,
      price: 7.8,
      roles: ['AM', 'LW', 'CM'] as Array<PlayerRoleModel>,
      substituted: false
    },
    {
      position: 'SUB6' as const,
      id: '5e861944',
      name: 'M. Politano',
      points: 0,
      price: 10,
      roles: ['RW', 'AM'] as Array<PlayerRoleModel>,
      substituted: true
    },
    {
      position: 'SUB7' as const,
      id: '03f946ed',
      name: 'A. Petagna',
      points: 0.5,
      price: 6.4,
      roles: ['FW'] as Array<PlayerRoleModel>,
      substituted: false
    }
  ],
  matchGroups: [
    {
      date: '2021-10-01',
      day: 'Saturday',
      matches: [
        {
          time: '18:00',
          homeClub: 'Juventus',
          awayClub: 'Roma',
          score: '3-1'
        },
        {
          time: '20:45',
          homeClub: 'Fiorentina',
          awayClub: 'Genoa',
          score: '0-0'
        }
      ]
    },
    {
      date: '2021-10-02',
      day: 'Sunday',
      matches: [
        {
          time: '12:30',
          homeClub: 'Inter',
          awayClub: 'Torino',
          score: '2-2'
        },
        {
          time: '15:00',
          homeClub: 'Milan',
          awayClub: 'Parma',
          score: '1-0'
        },
        {
          time: '15:00',
          homeClub: 'Atalanta',
          awayClub: 'Lazio',
          score: '4-2'
        },
        {
          time: '20:45',
          homeClub: 'Napoli',
          awayClub: 'Sassuolo',
          score: '-'
        }
      ]
    },
    {
      date: '2021-10-04',
      day: 'Tuesday',
      matches: [
        {
          time: '20:45',
          homeClub: 'Genoa',
          awayClub: 'Sampdoria',
          score: '-'
        }
      ]
    }
  ]
}

const availablePlayers: Array<AvailablePlayerModel> = [
  {
    id: 'f61c7c4e',
    name: 'F. Ribéry',
    roles: ['FW', 'LW', 'AM'],
    price: 9.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '79443529',
    name: 'D. Vlahović',
    roles: ['FW'],
    price: 10.5,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: '58e933d2',
    name: 'C. Kouamé',
    roles: ['FW', 'RW'],
    price: 5.4,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: 'a4fe8cda',
    name: 'P. Cutrone',
    roles: ['FW'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Italy'
  },
  {
    id: '0728bebe',
    name: 'G. Castrovilli',
    roles: ['CM', 'AM', 'LW'],
    price: 8.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: 'd995ed27',
    name: 'G. Bonaventura',
    roles: ['CM', 'AM'],
    price: 8.9,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '0e21fc3c',
    name: 'B. Valero',
    roles: ['CM', 'FW', 'AM'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '528492ea',
    name: 'A. Duncan',
    roles: ['CM'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '9e1577aa',
    name: 'C. Biraghi',
    roles: ['LB'],
    price: 9.5,
    clubId: 'abc123',
    nation: 'Italy'
  },
  {
    id: '983ff2c7',
    name: 'L. Venuti',
    roles: ['RB', 'LB'],
    price: 6.4,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: 'ff800ed8',
    name: 'P. Lirola',
    roles: ['RB', 'RW'],
    price: 4.8,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: 'e94d79e3',
    name: 'M. Cáceres',
    roles: ['CB', 'RB', 'LB'],
    price: 7.3,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: 'd9624b51',
    name: 'F. Ceccherini',
    roles: ['CB'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: 'bee704fc',
    name: 'N. Milenković',
    roles: ['CB', 'RB'],
    price: 8.8,
    clubId: 'abc123',
    nation: 'Italy'
  },
  {
    id: '085e71a7',
    name: 'B. Drągowski',
    roles: ['GK'],
    price: 8.8,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '5a2cb25d',
    name: 'S. Amrabat',
    roles: ['CM'],
    price: 8.1,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '3bb7af3b',
    name: 'R. Saponara',
    roles: ['AM'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: '4611e028',
    name: 'E. Pulgar',
    roles: ['CM'],
    price: 8.4,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: '78841da9',
    name: 'J. Callejón',
    roles: ['FW', 'RW', 'AM'],
    price: 4.6,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: '8c725b43',
    name: 'G. Pezzella',
    roles: ['CB'],
    price: 7.7,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: 'a4e85758',
    name: 'I. Igor',
    roles: ['CB', 'LB'],
    price: 5.7,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: '3ec3c1c9',
    name: 'L. Quarta',
    roles: ['CB', 'RB'],
    price: 7.9,
    clubId: 'abc123',
    nation: 'Italy'
  },
  {
    id: 'ae82fa48',
    name: 'V. Eysseric',
    roles: ['LW', 'CM', 'RW'],
    price: 5.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '6a189491',
    name: 'A. Barreca',
    roles: ['LB'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: 'fec5accc',
    name: 'A. Kokorin',
    roles: ['FW'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: 'd6a889ea',
    name: 'K. Malcuit',
    roles: ['RB'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Poland'
  },
  {
    id: 'dd52d069',
    name: 'C. Rodríguez',
    roles: ['LW'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: '32a33c68',
    name: 'M. Olivera',
    roles: ['CB'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: 'e1b0a70d',
    name: 'P. Terracciano',
    roles: ['GK'],
    price: 4.5,
    clubId: 'abc123',
    nation: 'Brazil'
  },
  {
    id: 'ef619b0b',
    name: 'A. Ramsey',
    roles: ['AM', 'LW', 'CM'],
    price: 7.8,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'dea698d9',
    name: 'C. Ronaldo',
    roles: ['FW'],
    price: 12.5,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: 'df3cda47',
    name: 'D. Kulusevski',
    roles: ['FW', 'RW', 'AM', 'RB'],
    price: 8.5,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: '4fa7cb9b',
    name: 'D. Costa',
    roles: ['LW'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Brazil'
  },
  {
    id: '01c3aff5',
    name: 'W. McKennie',
    roles: ['CM', 'RW', 'LW'],
    price: 7.2,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: '8794e251',
    name: 'A. Rabiot',
    roles: ['CM'],
    price: 8.3,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'd80b1835',
    name: 'G. Frabotta',
    roles: ['LW', 'LB'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'ae4e2852',
    name: 'M. Sciglio',
    roles: ['RB', 'LB'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: '0ab1f153',
    name: 'J. Cuadrado',
    roles: ['RW', 'LB', 'RB'],
    price: 11.6,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: '3b8674e6',
    name: 'R. Bentancur',
    roles: ['CM'],
    price: 9.6,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'e85fb9b8',
    name: 'G. Chiellini',
    roles: ['CB'],
    price: 6.8,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: 'e3cceee6',
    name: 'M. Demiral',
    roles: ['CB'],
    price: 5.6,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: '52ec54d0',
    name: 'L. Bonucci',
    roles: ['CB'],
    price: 7.8,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: '94b2001f',
    name: 'D. Danilo',
    roles: ['CB', 'RB', 'LB', 'CM'],
    price: 10.1,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: '01b8a2b2',
    name: 'W. Szczęsny',
    roles: ['GK'],
    price: 9.2,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: '129af0db',
    name: 'Á. Morata',
    roles: ['FW'],
    price: 10,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: '48b3dd60',
    name: 'A. Melo',
    roles: ['CM'],
    price: 7.3,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'c8c0dccd',
    name: 'M. Portanova',
    roles: ['AM'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Brazil'
  },
  {
    id: 'ee93c1a9',
    name: 'F. Bernardeschi',
    roles: ['LB', 'LW', 'RW'],
    price: 6.7,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'b0f7e36c',
    name: 'F. Chiesa',
    roles: ['RB', 'LW', 'RW', 'LB'],
    price: 11.1,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: 'e40d028b',
    name: 'G. Buffon',
    roles: ['GK'],
    price: 6.2,
    clubId: 'abc456',
    nation: 'Brazil'
  },
  {
    id: 'e0921a4f',
    name: 'P. Dybala',
    roles: ['FW'],
    price: 9.3,
    clubId: 'abc456',
    nation: 'Brazil'
  },
  {
    id: 'bf4d31d3',
    name: 'G. Vrioni',
    roles: ['GK'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: '0d82903c',
    name: 'A. Sandro',
    roles: ['CB', 'LB'],
    price: 8.8,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'd6e53a3a',
    name: 'M. Ligt',
    roles: ['CB'],
    price: 8.5,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: 'f6a06749',
    name: 'C. Pinsoglio',
    roles: ['GK'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: '620922ed',
    name: 'R. Drăgușin',
    roles: ['CB'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Brazil'
  },
  {
    id: '366be751',
    name: 'N. Fagioli',
    roles: ['CM'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Italy'
  },
  {
    id: 'e0ce7038',
    name: 'A. Pardo',
    roles: ['AM'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Brazil'
  },
  {
    id: 'c803c92a',
    name: 'F. Correia',
    roles: ['RW'],
    price: 4.5,
    clubId: 'abc456',
    nation: 'Poland'
  },
  {
    id: '89b2c8a9',
    name: 'D. Mertens',
    roles: ['FW', 'AM'],
    price: 9.3,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: '03f946ed',
    name: 'A. Petagna',
    roles: ['FW'],
    price: 6.4,
    clubId: 'abc789',
    nation: 'Italy'
  },
  {
    id: '2f557579',
    name: 'L. Insigne',
    roles: ['LW', 'AM'],
    price: 13.9,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: 'a69e4806',
    name: 'H. Lozano',
    roles: ['RW', 'LW', 'FW'],
    price: 10,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: '5e861944',
    name: 'M. Politano',
    roles: ['RW', 'AM'],
    price: 10,
    clubId: 'abc789',
    nation: 'Italy'
  },
  {
    id: 'a5296e55',
    name: 'P. Zieliński',
    roles: ['CM', 'AM', 'FW'],
    price: 11.6,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: 'e1cbb531',
    name: 'S. Lobotka',
    roles: ['CM'],
    price: 4.5,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: 'c0c7ff58',
    name: 'F. Peña',
    roles: ['CM'],
    price: 10.9,
    clubId: 'abc789',
    nation: 'Italy'
  },
  {
    id: 'cf007308',
    name: 'E. Elmas',
    roles: ['CM', 'LW'],
    price: 4.8,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: 'dc3fa3b5',
    name: 'D. Demme',
    roles: ['CM'],
    price: 7.4,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: '8c90fd7a',
    name: 'V. Osimhen',
    roles: ['FW'],
    price: 8.7,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: '2f3452eb',
    name: 'E. Hysaj',
    roles: ['LB', 'RB'],
    price: 7.9,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: 'da974c7b',
    name: 'K. Koulibaly',
    roles: ['CB'],
    price: 8.2,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: 'c707411f',
    name: 'K. Manolas',
    roles: ['CB'],
    price: 8,
    clubId: 'abc789',
    nation: 'Italy'
  },
  {
    id: '230a2fac',
    name: 'G. Lorenzo',
    roles: ['RB'],
    price: 11,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: '82b1198a',
    name: 'D. Ospina',
    roles: ['GK'],
    price: 7.7,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: 'c5adc293',
    name: 'F. Ghoulam',
    roles: ['LB'],
    price: 4.5,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: '16b9d5cf',
    name: 'N. Maksimović',
    roles: ['CB'],
    price: 7.3,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: '9413b19f',
    name: 'A. Meret',
    roles: ['GK'],
    price: 7.7,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: '59bf127b',
    name: 'T. Bakayoko',
    roles: ['CM'],
    price: 8.5,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: 'd6a889ea',
    name: 'K. Malcuit',
    roles: ['RB', 'LB'],
    price: 4.5,
    clubId: 'abc789',
    nation: 'Italy'
  },
  {
    id: '0b5a292a',
    name: 'M. Rui',
    roles: ['LB'],
    price: 8.7,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: '82dbf623',
    name: 'F. Llorente',
    roles: ['FW'],
    price: 4.5,
    clubId: 'abc789',
    nation: 'Poland'
  },
  {
    id: '4b3e1a38',
    name: 'A. Rrahmani',
    roles: ['CB'],
    price: 6.3,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: '3f1107d5',
    name: 'A. Cioffi',
    roles: ['RW'],
    price: 4.5,
    clubId: 'abc789',
    nation: 'Brazil'
  },
  {
    id: 'e95069bb',
    name: 'N. Baranovsky',
    roles: ['GK'],
    price: 4.5,
    clubId: 'abc789',
    nation: 'Poland'
  }
]
availablePlayers.sort((a, b) => b.price - a.price)

export const myTeamMockEndpoints: MyTeamCollection<'Client', AbortSignal> = {
  getMyTeam: {
    method: 'get',
    dispatch: () =>
      mockRequest({
        rounds: [
          {
            round: 1,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 2,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 3,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 4,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 5,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 6,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 7,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 8,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 9,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 10,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          },
          {
            round: 11,
            points: randomBetween(5, 120) + randomBetween(0, 3) * 0.25
          }
        ],
        activeRound: true,
        nextRound: false,
        ...team,
        players: team.players.map((player) => ({ ...player, stats: [] }))
      })
  },
  getMyTeamByRound: {
    method: 'get',
    dispatch: ({ round }) => {
      const formation = [
        '3-5-2',
        '3-4-3',
        '4-4-2',
        '4-3-3',
        '4-2-3-1',
        '5-3-2',
        '5-2-3'
      ][randomBetween(0, 6)] as FormationModel
      const substitutions = randomBetween(0, 7)

      return mockRequest({
        ...team,
        formation,
        players: [
          ...shuffle(
            formationBlockMap[formation].filter(({ position }) => position)
          ).map(({ position }, index) => ({
            position,
            substituted: round !== 'next' && index < substitutions
          })),
          ...shuffle(substitutePositions).map((position, index) => ({
            position,
            substituted: round !== 'next' && index < substitutions
          }))
        ]
          .map(({ position, substituted }) => ({
            position,
            substituted,
            role: getRoleByPosition(position!)
          }))
          .map(({ position, role, substituted }) => ({
            ...shuffle(availablePlayers).find(
              (player) => role === 'SUB' || player.roles.includes(role)
            )!,
            position: position!,
            points:
              round === 'next' || (substituted && role === 'SUB')
                ? 0
                : randomBetween(-5, 25) + randomBetween(0, 3) / 4,
            stats: [],
            substituted
          }))
      })
    }
  },
  getEditableTeam: {
    method: 'get',
    dispatch: () =>
      mockRequest({
        transferBudget: 150,
        totalTransfers: 2,
        wildcardsAvailable: 2,
        wildcardActive: false,
        ...team,
        players: team.players.filter(
          (player) =>
            player.name !== 'M. Politano' && player.name !== 'L. Insigne'
        ),
        lastRoundPlayers: [
          { id: 'dea698d9', name: 'C. Ronaldo' },
          { id: '2f557579', name: 'L. Insigne' },
          { id: 'a5296e55', name: 'P. Zieliński' },
          { id: 'b0f7e36c', name: 'F. Chiesa' },
          { id: '3b8674e6', name: 'R. Bentancur' },
          { id: 'cf007308', name: 'E. Elmas' },
          { id: 'ee93c1a9', name: 'F. Bernardeschi' },
          { id: 'e85fb9b8', name: 'G. Chiellini' },
          { id: 'c707411f', name: 'K. Manolas' },
          { id: '0ab1f153', name: 'J. Cuadrado' },
          { id: '9413b19f', name: 'A. Meret' },
          { id: '085e71a7', name: 'B. Drągowski' },
          { id: '8c725b43', name: 'G. Pezzella' },
          { id: '9e1577aa', name: 'C. Biraghi' },
          { id: '4611e028', name: 'E. Pulgar' },
          { id: 'ef619b0b', name: 'A. Ramsey' },
          { id: '5e861944', name: 'M. Politano' },
          { id: '03f946ed', name: 'A. Petagna' }
        ],
        availablePlayers: availablePlayers.slice(0, 15),
        clubs: [
          { id: 'abc123', name: 'Fiorentina' },
          { id: 'abc456', name: 'Juventus' },
          { id: 'abc789', name: 'Napoli' }
        ],
        nations: ['Italy', 'Poland', 'Brazil']
      })
  },
  getAvailablePlayers: {
    method: 'get',
    dispatch: () => mockRequest(availablePlayers)
  },
  resetTeam: {
    method: 'put',
    dispatch: () => mockRequest(undefined)
  },
  saveTeam: {
    method: 'put',
    dispatch: () => mockRequest(undefined)
  }
}
