import { formationBlockMap } from '@shared/constants/formations-block-map'
import { substitutePositions } from '@shared/constants/positions'
import { getRoleByPosition, identifyPlayer } from '@shared/helpers/players'
import { useViewTeamStore } from './view-team-store'

export const useFormationPlayers = () => {
  const [formation, players] = useViewTeamStore((state) => [
    state.formation,
    state.players
  ])

  const formationBlock = formationBlockMap[formation]

  return formationBlock.map((block) => {
    const { position } = block
    const player = players.find((_player) => _player.position === position)

    return position
      ? {
          space: block.space,
          role: getRoleByPosition(position),
          player
        }
      : {
          space: block.space
        }
  })
}

export const useSubstitutes = () => {
  const players = useViewTeamStore((state) => state.players)

  return substitutePositions.map((position) => {
    const player = players.find((_player) => _player.position === position)

    return {
      role: getRoleByPosition(position),
      player
    }
  })
}

export const useSelectedPlayer = () => {
  const [players, selectedPlayerId] = useViewTeamStore((state) => [
    state.players,
    state.selectedPlayerId
  ])

  if (selectedPlayerId) {
    return players.find((player) =>
      identifyPlayer(player, { id: selectedPlayerId })
    )
  }
}
