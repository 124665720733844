import { useAppStore } from './app-store'

export const useUser = () => {
  const [users, selectedUserId] = useAppStore((state) => [
    state.users,
    state.selectedUserId
  ])

  return users.find((user) => user.id === selectedUserId)
}
