import create from 'zustand'
import { ClubDetailModel } from '@shared/models/club-detail'

export interface ClubDetailState {
  clubDetail: ClubDetailModel | undefined
}

export const initialState: ClubDetailState = {
  clubDetail: undefined
}

export const useClubDetailStore = create<ClubDetailState>(() => initialState)
