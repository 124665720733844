import {
  PlayerPerformanceViewModel,
  StatsViewModel
} from '@root/models/player-performance-view'
import { useMatchDetailStore } from './match-detail-store'

export const useClubPlayerPerformances = (
  club: 'home' | 'away'
): Array<PlayerPerformanceViewModel> => {
  const matchDetail = useMatchDetailStore((state) => state.matchDetail)

  if (matchDetail) {
    const clubId = matchDetail[`${club}Club` as const].id
    return matchDetail.playerPerformances
      .filter((playerPerformance) => playerPerformance.clubId === clubId)
      .map((playerPerformance) => ({
        ...playerPerformance,
        href: `/players/${playerPerformance.id}`,
        stats: Object.keys(playerPerformance.stats)
          .map((statCategory) => ({
            statCategory,
            value: playerPerformance.stats[statCategory] || '-'
          }))
          .reduce(
            (stats, { statCategory, value }) => ({
              ...stats,
              [statCategory]: value
            }),
            {} as StatsViewModel
          ),
        totalPoints: 0,
        price: 0
      }))
      .sort((a, b) => b.points - a.points)
  }

  return []
}
