import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { statCategoryColumns } from '../helpers/stat-columns'
import { PlayerPerformanceViewModel } from '../models/player-performance-view'

const useStyles = makeStyles((theme) => ({
  statColumn: {
    padding: `0 ${theme.spacing(0.5)}px`
  },
  price: {
    whiteSpace: 'nowrap'
  }
}))

interface Props {
  playerPerformances: Array<PlayerPerformanceViewModel>
  extendedTable?: boolean
  againstClubs?: boolean
}

export const PlayerPerformances: FC<Props> = ({
  playerPerformances,
  extendedTable,
  againstClubs
}) => {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{againstClubs ? 'Against' : 'Player'}</TableCell>
            {!extendedTable && (
              <TableCell title="Starting position">SP</TableCell>
            )}
            {statCategoryColumns.map((statCategoryColumn, index) => (
              <TableCell
                key={index}
                title={statCategoryColumn.description}
                className={classes.statColumn}
              >
                {statCategoryColumn.shortDescription}
              </TableCell>
            ))}
            <TableCell>Points</TableCell>
            {extendedTable && <TableCell>Total</TableCell>}
            {extendedTable && <TableCell>Price</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {playerPerformances.map((playerPerformance, index) => (
            <TableRow key={index}>
              <TableCell>
                <Link component={RouterLink} to={playerPerformance.href}>
                  {playerPerformance.name}
                </Link>
              </TableCell>
              {!extendedTable && (
                <TableCell
                  title="Starting position"
                  className={classes.statColumn}
                >
                  {playerPerformance.position}
                </TableCell>
              )}
              {statCategoryColumns.map((statCategoryColumn, statIndex) => (
                <TableCell
                  key={statIndex}
                  align="center"
                  title={statCategoryColumn.description}
                  className={classes.statColumn}
                >
                  {playerPerformance.stats[statCategoryColumn.key]}
                </TableCell>
              ))}
              <TableCell>{playerPerformance.points}</TableCell>
              {extendedTable && (
                <TableCell>{playerPerformance.totalPoints}</TableCell>
              )}
              {extendedTable && (
                <TableCell className={classes.price}>
                  € {playerPerformance.price}M
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
