import create from 'zustand'

export interface MyTeamState {
  editing: boolean
}

export const initialState: MyTeamState = {
  editing: false
}

export const useMyTeamStore = create<MyTeamState>(() => initialState)
