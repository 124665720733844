import {
  PlayerPerformanceViewModel,
  StatsViewModel
} from '@root/models/player-performance-view'
import { usePlayerDetailStore } from './player-detail-store'

export const usePerformances = (): Array<PlayerPerformanceViewModel> => {
  const playerDetail = usePlayerDetailStore((state) => state.playerDetail)

  if (playerDetail) {
    return playerDetail.performances
      .map((playerPerformance) => ({
        ...playerPerformance,
        href: `/matches/${playerPerformance.id}`,
        stats: Object.keys(playerPerformance.stats)
          .map((statCategory) => ({
            statCategory,
            value: playerPerformance.stats[statCategory] || '-'
          }))
          .reduce(
            (stats, { statCategory, value }) => ({
              ...stats,
              [statCategory]: value
            }),
            {} as StatsViewModel
          ),
        totalPoints: 0,
        price: 0
      }))
      .sort((a, b) => b.points - a.points)
  }

  return []
}
