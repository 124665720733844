import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import { FC, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { fetchMatches, setSelectedRound } from './store/matches-actions'
import { useGroupedMatches } from './store/matches-selectors'
import { useMatchesStore } from './store/matches-store'

export const Matches: FC = () => {
  const [rounds, selectedRound] = useMatchesStore((state) => [
    state.rounds,
    state.selectedRound
  ])
  const matchGroups = useGroupedMatches()

  useEffect(() => {
    fetchMatches(selectedRound)
  }, [selectedRound])

  return (
    <Paper>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Select
                value={selectedRound}
                onChange={(e) => setSelectedRound(e.target.value as number)}
              >
                {rounds.map((round, index) => (
                  <MenuItem key={index} value={round}>
                    Round {round}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          {matchGroups.map(({ date, day, matches }, index) => (
            <Grid key={index} item xs={12}>
              <Box mb={2}>
                <Paper>
                  <Box p={2}>
                    <Typography variant="subtitle1">
                      {date}, {day}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
              <Typography variant="h6">
                {matches.map((match, matchIndex) => (
                  <div key={matchIndex}>
                    <Box py={1} display="flex" justifyContent="center">
                      <Box
                        width="80%"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Box position="absolute" left={0}>
                          {match.time}
                        </Box>
                        <div>
                          <Grid container spacing={3}>
                            <Grid item>
                              <Link
                                component={RouterLink}
                                to={`/clubs/${match.homeClub.id}`}
                              >
                                {match.homeClub.name}
                              </Link>
                            </Grid>
                            <Grid item>
                              {match.homeClub.score}-{match.awayClub.score}
                            </Grid>
                            <Grid item>
                              <Link
                                component={RouterLink}
                                to={`/clubs/${match.awayClub.id}`}
                              >
                                {match.awayClub.name}
                              </Link>
                            </Grid>
                          </Grid>
                        </div>
                        <Box position="absolute" right={0}>
                          <Button
                            component={RouterLink}
                            to={`/matches/${match.id}`}
                            endIcon={<NavigateNext />}
                          >
                            Match Details
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                ))}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  )
}
