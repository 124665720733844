import { Box, Grid } from '@material-ui/core'
import { FC } from 'react'
import { PlayerBackground } from '../../player-background'
import { PlayerRole } from '../../player-role'
import { selectPlayerId } from '../store/view-team-actions'
import { useFormationPlayers } from '../store/view-team-selectors'

export const FormationPlayers: FC = () => {
  const formationPlayers = useFormationPlayers()

  return (
    <PlayerBackground fieldDetails>
      <Grid container justify="center" spacing={2}>
        {formationPlayers.map(({ space, role, player }, index) => (
          <Grid key={index} item xs={space}>
            <Box minHeight={114}>
              {role && (
                <PlayerRole
                  color={player?.substituted ? 'yellow' : 'green'}
                  role={role}
                  name={player?.name}
                  price={player?.price}
                  points={player?.points}
                  click={() => player && selectPlayerId(player.id)}
                />
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </PlayerBackground>
  )
}
