import { endpoints } from '@root/transport/http/endpoints'
import { initialState, useLeagueStore } from './league-store'

const { setState } = useLeagueStore

export const resetLeague = () => setState({ ...initialState })
export const fetchLeague = async () => {
  setState({
    league: await endpoints.getLeague.dispatch()
  })
}
