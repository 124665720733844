import { endpoints } from '@root/transport/http/endpoints'
import { useMatchesStore } from './matches-store'

const { setState } = useMatchesStore

export const fetchMatches = async (round: number) => {
  const { rounds, defaultRound, matches } = await endpoints.getMatches.dispatch(
    round || undefined
  )

  setState({
    rounds,
    selectedRound: round || defaultRound,
    matches
  })
}

export const setSelectedRound = (round: number) =>
  setState({ selectedRound: round })
