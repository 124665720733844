import create from 'zustand'
import { GetLeagueResponseModel } from '@shared/transport/http/collection/collection'

export interface LeagueState {
  league: Array<GetLeagueResponseModel>
}

export const initialState: LeagueState = {
  league: []
}

export const useLeagueStore = create<LeagueState>(() => initialState)
