"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formationBlockMap = void 0;
exports.formationBlockMap = {
    '3-4-3': [
        { space: 12, position: 'FW' },
        { space: 1 },
        { space: 5, position: 'LAM' },
        { space: 5, position: 'RAM' },
        { space: 1 },
        { space: 3, position: 'LW' },
        { space: 3 },
        { space: 3 },
        { space: 3, position: 'RW' },
        { space: 3 },
        { space: 3, position: 'LCM' },
        { space: 3, position: 'RCM' },
        { space: 3 },
        { space: 2 },
        { space: 3, position: 'LCB' },
        { space: 2, position: 'CB' },
        { space: 3, position: 'RCB' },
        { space: 2 },
        { space: 12, position: 'GK' }
    ],
    '3-5-2': [
        { space: 3 },
        { space: 3, position: 'LFW' },
        { space: 3, position: 'RFW' },
        { space: 3 },
        { space: 12 },
        { space: 3, position: 'LW' },
        { space: 3, position: 'LCM' },
        { space: 3, position: 'RCM' },
        { space: 3, position: 'RW' },
        { space: 12, position: 'CM' },
        { space: 2 },
        { space: 3, position: 'LCB' },
        { space: 2, position: 'CB' },
        { space: 3, position: 'RCB' },
        { space: 2 },
        { space: 12, position: 'GK' }
    ],
    '4-4-2': [
        { space: 3 },
        { space: 3, position: 'LFW' },
        { space: 3, position: 'RFW' },
        { space: 3 },
        { space: 12 },
        { space: 3, position: 'LW' },
        { space: 3, position: 'LCM' },
        { space: 3, position: 'RCM' },
        { space: 3, position: 'RW' },
        { space: 12 },
        { space: 3, position: 'LB' },
        { space: 3, position: 'LCB' },
        { space: 3, position: 'RCB' },
        { space: 3, position: 'RB' },
        { space: 12, position: 'GK' }
    ],
    '4-3-3': [
        { space: 12, position: 'FW' },
        { space: 3, position: 'LW' },
        { space: 6 },
        { space: 3, position: 'RW' },
        { space: 4, position: 'LCM' },
        { space: 4, position: 'RCM' },
        { space: 12, position: 'CM' },
        { space: 3, position: 'LB' },
        { space: 3, position: 'LCB' },
        { space: 3, position: 'RCB' },
        { space: 3, position: 'RB' },
        { space: 12, position: 'GK' }
    ],
    '4-2-3-1': [
        { space: 12, position: 'FW' },
        { space: 3, position: 'LW' },
        { space: 6, position: 'AM' },
        { space: 3, position: 'RW' },
        { space: 12 },
        { space: 3 },
        { space: 3, position: 'LCM' },
        { space: 3, position: 'RCM' },
        { space: 3 },
        { space: 3, position: 'LB' },
        { space: 3, position: 'LCB' },
        { space: 3, position: 'RCB' },
        { space: 3, position: 'RB' },
        { space: 12, position: 'GK' }
    ],
    '5-2-3': [
        { space: 12, position: 'FW' },
        { space: 1 },
        { space: 5, position: 'LAM' },
        { space: 5, position: 'RAM' },
        { space: 1 },
        { space: 3 },
        { space: 3, position: 'LCM' },
        { space: 3, position: 'RCM' },
        { space: 3 },
        { space: 3, position: 'LB' },
        { space: 6 },
        { space: 3, position: 'RB' },
        { space: 2 },
        { space: 3, position: 'LCB' },
        { space: 2, position: 'CB' },
        { space: 3, position: 'RCB' },
        { space: 2 },
        { space: 12, position: 'GK' }
    ],
    '5-3-2': [
        { space: 3 },
        { space: 3, position: 'LFW' },
        { space: 3, position: 'RFW' },
        { space: 3 },
        { space: 12 },
        { space: 3 },
        { space: 3, position: 'LCM' },
        { space: 3, position: 'RCM' },
        { space: 3 },
        { space: 2, position: 'LB' },
        { space: 3 },
        { space: 2, position: 'CM' },
        { space: 3 },
        { space: 2, position: 'RB' },
        { space: 2 },
        { space: 3, position: 'LCB' },
        { space: 2, position: 'CB' },
        { space: 3, position: 'RCB' },
        { space: 2 },
        { space: 12, position: 'GK' }
    ]
};
