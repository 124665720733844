import create from 'zustand'
import { PlayerDetailModel } from '@shared/models/player-detail'

export interface PlayerDetailState {
  playerDetail: PlayerDetailModel | undefined
}

export const initialState: PlayerDetailState = {
  playerDetail: undefined
}

export const usePlayerDetailStore = create<PlayerDetailState>(
  () => initialState
)
