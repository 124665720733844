import { isEditing } from '@root/pages/my-team/helpers/editing'
import { getUserId } from '@root/pages/my-team/helpers/user-id'
import { endpoints } from '@root/transport/http/endpoints'
import { initialViewTeamState, useViewTeamStore } from './view-team-store'

const { setState } = useViewTeamStore

export const resetViewableTeam = () => setState({ ...initialViewTeamState })
export const fetchMyTeam = async () => {
  if (!isEditing()) {
    const { rounds, nextRound, activeRound, formation, players, matchGroups } =
      await endpoints.myTeam.getMyTeam.dispatch(getUserId())

    setState({
      fetching: false,
      nextRound,
      rounds,
      formation,
      players,
      matchGroups
    })

    await new Promise((resolve) => setTimeout(resolve, 200))

    if (activeRound || !nextRound) {
      setState({
        selectedRound: rounds[rounds.length - 1].round
      })
    }
  }
}

export const selectRound = async (round: number | 'next') => {
  setState({
    selectedRound: round
  })

  const { formation, players, matchGroups } =
    await endpoints.myTeam.getMyTeamByRound.dispatch({
      userId: getUserId(),
      round
    })

  setState({
    formation,
    players,
    matchGroups
  })
}

export const selectPlayerId = (playerId: string | undefined) =>
  setState({ selectedPlayerId: playerId })
