import create from 'zustand'
import { ClubModel } from '@shared/models/club'

export interface ClubsState {
  clubs: Array<ClubModel>
}

export const initialState: ClubsState = {
  clubs: []
}

export const useClubsStore = create<ClubsState>(() => initialState)
