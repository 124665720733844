import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FC, useEffect } from 'react'
import { Loader } from '@root/components/loader'
import { fetchLeague, resetLeague } from './store/league-actions'
import { useLeagueStore } from './store/league-store'

export const Leagues: FC = () => {
  const league = useLeagueStore((state) => state.league)

  useEffect(() => {
    fetchLeague()

    return resetLeague
  }, [])

  if (!league.length) {
    return <Loader />
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Main League
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Total
                    </TableCell>
                    {[...Array(league[0].rounds.length)].map((_, index) => (
                      <TableCell key={index + 1}>{index + 1}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {league
                    .slice()
                    .sort(
                      (a, b) =>
                        (b.rounds.slice().reverse().find(Boolean) || 0) -
                        (a.rounds.slice().reverse().find(Boolean) || 0)
                    )
                    .map((leagueUser, index) => (
                      <TableRow key={index}>
                        <TableCell>{leagueUser.user}</TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                          {leagueUser.rounds.reduce(
                            (points, round) => points + round,
                            0
                          )}
                          pts
                        </TableCell>
                        {leagueUser.rounds.map((round, index2) => (
                          <TableCell
                            key={index2}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {round} pts
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
