"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mayPlayerPlayPosition = exports.getRoleByPosition = exports.identifyPlayer = void 0;
const positions_1 = require("@shared/constants/positions");
const identifyPlayer = (a, b) => a.id === b.id;
exports.identifyPlayer = identifyPlayer;
const getRoleByPosition = (position) => {
    const role = Object.keys(positions_1.rolePositionMap).find((role) => positions_1.rolePositionMap[role].find((_position) => _position === position));
    if (role) {
        return role;
    }
    throw new Error();
};
exports.getRoleByPosition = getRoleByPosition;
const mayPlayerPlayPosition = (availablePlayer, position) => availablePlayer.roles.some((role) => positions_1.rolePositionMap[role].includes(position));
exports.mayPlayerPlayPosition = mayPlayerPlayPosition;
