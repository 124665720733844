import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  makeStyles
} from '@material-ui/core'
import { FC } from 'react'
import { formations } from '@shared/constants/formations'
import { FormationModel } from '@shared/models/team/formation'
import { PlayerBackground } from '../../player-background'
import { PlayerRole } from '../../player-role'
import { selectPosition, setFormation } from '../store/edit-team-actions'
import { useFormationPlayers } from '../store/edit-team-selectors'
import { useEditTeamStore } from '../store/edit-team-store'

const useStyles = makeStyles((theme) => ({
  formationLabel: {
    color: `${theme.palette.common.white} !important`
  },
  formationSelect: {
    color: 'rgba(255, 255, 255, 0.87)',
    '&::before': {
      borderColor: 'rgba(255, 255, 255, 0.87) !important'
    },
    '& svg': {
      color: 'inherit'
    }
  }
}))

export const FormationPlayers: FC = () => {
  const formation = useEditTeamStore((state) => state.formation)
  const formationPlayers = useFormationPlayers()
  const classes = useStyles()

  return (
    <PlayerBackground fieldDetails>
      <Box position="absolute" right={16} top={16}>
        <FormControl variant="filled">
          <InputLabel className={classes.formationLabel}>Formation</InputLabel>
          <Select
            value={formation}
            color="secondary"
            onChange={(e) => setFormation(e.target.value as FormationModel)}
            className={classes.formationSelect}
          >
            {formations.map((_formation, index) => (
              <MenuItem key={index} value={_formation}>
                {_formation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container justify="center" spacing={2}>
        {formationPlayers.map(
          (
            {
              space,
              position,
              role,
              mayTransfer,
              unavailable,
              selected,
              player
            },
            index
          ) => (
            <Grid key={index} item xs={space}>
              <Box minHeight={114}>
                {role && (
                  <PlayerRole
                    color={mayTransfer ? 'yellow' : 'grey'}
                    unavailable={unavailable}
                    role={role}
                    name={player?.name}
                    price={player?.price}
                    selected={selected}
                    click={() =>
                      position && !unavailable && selectPosition(position)
                    }
                  />
                )}
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </PlayerBackground>
  )
}
