import {
  AppBar,
  Box,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core'
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Loader } from '@root/components/loader'
import { fetchUsers, switchUser } from '@root/store/app-actions'
import { useAppStore } from '@root/store/app-store'
import { getHistory } from '@root/utils/history'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxWidth: 1280,
    minHeight: '80vh',
    margin: theme.spacing(4, 'auto', 0, 'auto')
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    padding: 0
  },
  title: {
    position: 'absolute',
    left: theme.spacing(2),
    color: 'white'
  },
  content: {
    marginBottom: theme.spacing(2)
  },
  tabsIndicator: {
    background: 'rgba(255, 255, 255, 0.87)'
  },
  userSelect: {
    position: 'absolute',
    right: theme.spacing(2),
    color: 'white',
    minWidth: 120,
    '&::before': {
      borderColor: 'rgba(255, 255, 255, 0.87) !important'
    },
    '& svg': {
      color: 'inherit'
    }
  }
}))

export const Shell: FC = ({ children }) => {
  const [users, selectedUserId] = useAppStore((state) => [
    state.users,
    state.selectedUserId
  ])
  const classes = useStyles()
  const { pathname } = useLocation()

  useEffect(() => {
    fetchUsers()
  }, [])

  if (!users.length) {
    return <Loader />
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4" className={classes.title}>
              Fantasy (alpha)
            </Typography>
            <Tabs
              classes={{ indicator: classes.tabsIndicator }}
              value={(pathname || '/matches').split('/').slice(0, 2).join('/')}
              onChange={(e, newValue) => getHistory().push(newValue)}
            >
              <Tab value="/matches" label="Matches" />
              <Tab value="/clubs" label="Clubs" />
              <Tab value="/leagues" label="Leagues" />
              <Tab value="/my-team" label="My team" />
            </Tabs>
            <Select
              value={selectedUserId || ''}
              onChange={(e) => switchUser(e.target.value as string)}
              className={classes.userSelect}
            >
              {users.map((user, index) => (
                <MenuItem key={index} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Toolbar>
      </AppBar>
      <main>{children}</main>
    </div>
  )
}
