import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core'
import { FC } from 'react'
import { selectPlayerId } from '../store/view-team-actions'
import { useSelectedPlayer } from '../store/view-team-selectors'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 36,
    height: 36,
    border: `3px solid ${theme.palette.secondary.light}`,
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: 8
  }
}))

export const PlayerDialog: FC = () => {
  const selectedPlayer = useSelectedPlayer()
  const classes = useStyles()

  const close = () => selectPlayerId(undefined)

  return (
    <Dialog
      open={Boolean(selectedPlayer)}
      fullWidth
      maxWidth="xs"
      onClose={close}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <div>{selectedPlayer?.name}</div>
          <div />
        </Box>
      </DialogTitle>
      <DialogContent>
        <List>
          {selectedPlayer?.stats.map(({ stat, amount, points }, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>{amount}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={stat} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle1">{points} pts</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <ListItem>
            <ListItemText primary="Total" />
            <ListItemSecondaryAction>
              <Typography variant="subtitle1">
                {selectedPlayer?.points} pts
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
