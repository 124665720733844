import create from 'zustand'
import { FormationModel } from '@shared/models/team/formation'
import { MatchGroupModel } from '@shared/models/team/match-group'
import { ViewablePlayerModel } from '@shared/models/team/player/viewable-player'
import { RoundModel } from '@shared/models/team/round'

export interface ViewTeamState {
  fetching: boolean
  rounds: Array<RoundModel>
  nextRound: boolean
  selectedRound: number | 'next'
  formation: FormationModel
  players: Array<ViewablePlayerModel>
  substitutes: Array<ViewablePlayerModel>
  matchGroups: Array<MatchGroupModel>
  selectedPlayerId: string | undefined
}

export const initialViewTeamState: ViewTeamState = {
  fetching: true,
  rounds: [],
  nextRound: true,
  selectedRound: 'next',
  formation: '4-4-2',
  players: [],
  substitutes: [],
  matchGroups: [],
  selectedPlayerId: undefined
}

export const useViewTeamStore = create<ViewTeamState>(
  () => initialViewTeamState
)
