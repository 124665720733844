import { identifyPlayer } from '@shared/helpers/players'
import { EditablePlayerModel } from '@shared/models/team/player/editable-player'
import { PlayerModel } from '@shared/models/team/player/player'

export const getTransfersLeft = (
  totalTransfers: number,
  wildcardActive: boolean,
  players: Array<EditablePlayerModel>,
  lastRoundPlayers: Array<PlayerModel>
): number =>
  (wildcardActive ? Infinity : totalTransfers) -
  players.filter(
    (player) =>
      !lastRoundPlayers.find((lastRoundPlayer) =>
        identifyPlayer(lastRoundPlayer, player)
      )
  ).length
