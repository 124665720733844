import { Box, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { fetchClubs } from './store/clubs-actions'
import { useClubsStore } from './store/clubs-store'

export const Clubs: FC = () => {
  const clubs = useClubsStore((state) => state.clubs)

  useEffect(() => {
    fetchClubs()
  }, [])

  return (
    <Paper>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2}>
          {clubs.map(({ id, name }, index) => (
            <Grid key={index} item xs={3}>
              <Box mb={2}>
                <Paper>
                  <Box
                    py={2}
                    pl={2}
                    pr={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">{name}</Typography>
                    <IconButton
                      color="primary"
                      component={Link}
                      to={`/clubs/${id}`}
                    >
                      <ArrowForward />
                    </IconButton>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  )
}
