import create from 'zustand'
import { ClubModel } from '@shared/models/team/club'
import { FormationModel } from '@shared/models/team/formation'
import { AvailablePlayerModel } from '@shared/models/team/player/available-player'
import { EditablePlayerModel } from '@shared/models/team/player/editable-player'
import { PlayerModel } from '@shared/models/team/player/player'
import { PositionModel } from '@shared/models/team/player/position/position'
import { PlayerFilterModel } from './models/player-filter'
import { ActiveDialogModel } from '../models/active-dialog'

export interface EditTeamState {
  viewState: 'fetching' | 'viewing' | 'resetting' | 'saving'
  activeDialog: ActiveDialogModel
  transferBudget: number
  totalTransfers: number
  wildcardsAvailable: number
  wildcardActive: boolean
  formation: FormationModel
  players: Array<EditablePlayerModel>
  lastRoundPlayers: Array<PlayerModel>
  availablePlayers: Array<AvailablePlayerModel>
  clubs: Array<ClubModel>
  nations: Array<string>
  selectedPosition: PositionModel | undefined
  filters: PlayerFilterModel
}

export const initialEditTeamState: EditTeamState = {
  viewState: 'fetching',
  activeDialog: 'none',
  transferBudget: 0,
  totalTransfers: 0,
  wildcardsAvailable: 0,
  wildcardActive: false,
  formation: '4-4-2',
  players: [],
  lastRoundPlayers: [],
  availablePlayers: [],
  clubs: [],
  nations: [],
  selectedPosition: undefined,
  filters: {
    page: 1,
    searchQuery: '',
    clubId: '',
    nation: '',
    role: undefined
  }
}

export const useEditTeamStore = create<EditTeamState>(
  () => initialEditTeamState
)
