import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import { Loader } from '@root/components/loader'
import { PlayerPerformances } from '@root/components/player-performances'
import { fetchClubDetail, resetClubDetail } from './store/club-detail-actions'
import { usePlayerPerformances } from './store/club-detail-selectors'
import { useClubDetailStore } from './store/club-detail-store'

export const ClubDetail: FC = () => {
  const clubDetail = useClubDetailStore((state) => state.clubDetail)
  const playerPerformances = usePlayerPerformances()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    fetchClubDetail(id)

    return resetClubDetail
  }, [id])

  if (!clubDetail) {
    return <Loader />
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {clubDetail?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PlayerPerformances
              playerPerformances={playerPerformances}
              extendedTable
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
