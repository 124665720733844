import { Box, Button, Paper, Tab, Tabs, makeStyles } from '@material-ui/core'
import { FC } from 'react'
import { toggleEditing } from '../../../store/my-team-actions'
import { selectRound } from '../store/view-team-actions'
import { useViewTeamStore } from '../store/view-team-store'

const useStyles = makeStyles((theme) => ({
  tab: {
    width: 120,
    minWidth: 'auto',
    textTransform: 'none'
  }
}))

export const Navigation: FC = () => {
  const [rounds, nextRound, selectedRound] = useViewTeamStore((state) => [
    state.rounds,
    state.nextRound,
    state.selectedRound
  ])
  const classes = useStyles()

  return (
    <Paper>
      <Box p={2} display="flex" alignItems="center" maxWidth="100%">
        <Tabs
          variant="scrollable"
          value={selectedRound}
          onChange={(e, value) => selectRound(value)}
        >
          {rounds.map((round, index) => (
            <Tab
              key={index}
              label={
                <>
                  <div>ROUND {round.round}</div>
                  <Box fontWeight={700} fontSize={14}>
                    {round.points} pts
                  </Box>
                </>
              }
              wrapped
              value={round.round}
              classes={{ root: classes.tab }}
            />
          ))}
          {nextRound && <Tab label="Next round" value="next" />}
        </Tabs>
        <Box flexGrow={1} ml={2} textAlign="right">
          <Button
            color="secondary"
            variant="contained"
            disabled={!nextRound}
            onClick={toggleEditing}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}
