import { formationBlockMap } from '@shared/constants/formations-block-map'
import { substitutePositions } from '@shared/constants/positions'
import {
  getRoleByPosition,
  identifyPlayer,
  mayPlayerPlayPosition
} from '@shared/helpers/players'
import {
  getTransferBudgetLeft,
  maySaveBaseTeam,
  mayUseWildcard
} from '@shared/helpers/team'
import { AvailablePlayerModel } from '@shared/models/team/player/available-player'
import { PlayerRoleModel } from '@shared/models/team/player/role/player-role'
import { initialEditTeamState, useEditTeamStore } from './edit-team-store'
import { getTransfersLeft } from './helpers/get-transfers-left'
import { RoleFilterModel } from '../models/role-filter'

const includesRole = (
  playerRoles: Array<PlayerRoleModel>,
  roles: Array<PlayerRoleModel>
) => roles.some((role) => playerRoles.includes(role))

const playerHasRole = (
  player: AvailablePlayerModel,
  role: RoleFilterModel
): boolean => {
  switch (role) {
    case 'Defenders':
      return includesRole(player.roles, ['CB', 'LB', 'RB'])
    case 'Midfielders':
      return includesRole(player.roles, ['CM', 'AM'])
    case 'Attackers':
      return includesRole(player.roles, ['LW', 'RW', 'FW'])
    default:
      return includesRole(player.roles, [role])
  }
}

const useFilteredPlayers = () => {
  const [availablePlayers, { searchQuery, clubId, nation, role }] =
    useEditTeamStore((state) => [state.availablePlayers, state.filters])

  return availablePlayers.filter(
    (player) =>
      (!searchQuery ||
        player.name.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (!clubId || player.clubId.includes(clubId)) &&
      (!nation || player.nation.includes(nation)) &&
      (!role || playerHasRole(player, role))
  )
}

export const useTransferBudgetLeft = () => {
  const [transferBudget, players] = useEditTeamStore((state) => [
    state.transferBudget,
    state.players
  ])

  return getTransferBudgetLeft(transferBudget, players)
}

export const useTransfersLeft = () => {
  const [totalTransfers, wildcardActive, players, lastRoundPlayers] =
    useEditTeamStore((state) => [
      state.totalTransfers,
      state.wildcardActive,
      state.players,
      state.lastRoundPlayers
    ])

  return getTransfersLeft(
    totalTransfers,
    wildcardActive,
    players,
    lastRoundPlayers
  )
}

export const useMayUseWildcard = () => {
  const [totalTransfers, wildcardsAvailable, wildcardActive] = useEditTeamStore(
    (state) => [
      state.totalTransfers,
      state.wildcardsAvailable,
      state.wildcardActive
    ]
  )

  return !wildcardActive && mayUseWildcard(totalTransfers, wildcardsAvailable)
}

export const useMayReset = () => {
  const lastRoundPlayers = useEditTeamStore((state) => state.lastRoundPlayers)

  return Boolean(lastRoundPlayers.length)
}

export const useMaySave = () => {
  const [transferBudget, formation, players] = useEditTeamStore((state) => [
    state.transferBudget,
    state.formation,
    state.players
  ])

  return maySaveBaseTeam(transferBudget, formation, players)
}

export const useFormationPlayers = () => {
  const [formation, players, selectedPosition] = useEditTeamStore((state) => [
    state.formation,
    state.players,
    state.selectedPosition
  ])

  const formationBlock = formationBlockMap[formation]
  const selectedSubstitute =
    selectedPosition &&
    getRoleByPosition(selectedPosition) === 'SUB' &&
    players.find((player) => player.position === selectedPosition)

  return formationBlock.map((block) => {
    const { position } = block
    const blockPlayer = players.find((player) => player.position === position)

    const mayTransfer =
      blockPlayer &&
      selectedSubstitute &&
      mayPlayerPlayPosition(selectedSubstitute, blockPlayer.position)

    return position
      ? {
          space: block.space,
          position,
          role: getRoleByPosition(position),
          unavailable: selectedSubstitute && !mayTransfer,
          mayTransfer: selectedSubstitute && mayTransfer,
          selected: selectedPosition === position,
          player: blockPlayer
        }
      : {
          space: block.space
        }
  })
}

export const useSubstitutes = () => {
  const [players, selectedPosition] = useEditTeamStore((state) => [
    state.players,
    state.selectedPosition
  ])

  const selectedFormationPlayer =
    selectedPosition &&
    getRoleByPosition(selectedPosition) !== 'SUB' &&
    players.find((player) => player.position === selectedPosition)

  return substitutePositions.map((position) => {
    const player = players.find((_player) => _player.position === position)
    const mayTransfer =
      selectedFormationPlayer &&
      player &&
      mayPlayerPlayPosition(player, selectedFormationPlayer.position)

    return {
      position,
      role: getRoleByPosition(position),
      unavailable: selectedFormationPlayer && !mayTransfer,
      mayTransfer: selectedFormationPlayer && mayTransfer,
      selected: selectedPosition === position,
      player
    }
  })
}

export const useMayClearFilters = () => {
  const filters = useEditTeamStore((state) => state.filters)

  return Object.keys(filters).some(
    (column) => filters[column] !== initialEditTeamState.filters[column]
  )
}

export const useAvailablePlayers = () => {
  const filteredPlayers = useFilteredPlayers()
  const [players, selectedPosition, page] = useEditTeamStore((state) => [
    state.players,
    state.selectedPosition,
    state.filters.page
  ])

  return filteredPlayers.slice(0, page * 15).map((player) => {
    // May transfer this available player if ...
    const mayTransfer =
      // There's a selected position
      selectedPosition &&
      // The role is SUB or the available player can play in the selected role
      (getRoleByPosition(selectedPosition) === 'SUB' ||
        mayPlayerPlayPosition(player, selectedPosition)) &&
      // The available player is not amongst the players
      !players.find((_player) => identifyPlayer(_player, player))

    return {
      ...player,
      unavailable: selectedPosition && !mayTransfer,
      mayTransfer
    }
  })
}

export const useMayShowMorePlayers = () => {
  const filteredPlayers = useFilteredPlayers()
  const filters = useEditTeamStore((state) => state.filters)

  return filters.page < filteredPlayers.length / 15
}
