import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { FC } from 'react'

interface Props {
  open: boolean
  disabled?: boolean
  title: string
  contentText: string
  close: () => void
  confirm?: () => void
}

export const ConfirmDialog: FC<Props> = ({
  open,
  disabled,
  title,
  contentText,
  close,
  confirm
}) => (
  <Dialog open={open} disableBackdropClick={disabled} onClose={close}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{contentText}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" autoFocus disabled={disabled} onClick={close}>
        Close
      </Button>
      {confirm && (
        <Button color="primary" autoFocus disabled={disabled} onClick={confirm}>
          Confirm
        </Button>
      )}
    </DialogActions>
  </Dialog>
)
