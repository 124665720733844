import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core'
import { FC } from 'react'
import { PlayerRoleModel } from '@shared/models/team/player/role/player-role'
import { roles } from '../constants/roles'
import { clearFilters, updateFilters } from '../store/edit-team-actions'
import { useMayClearFilters } from '../store/edit-team-selectors'
import { useEditTeamStore } from '../store/edit-team-store'

export const Filter: FC = () => {
  const [clubs, nations, filters] = useEditTeamStore((state) => [
    state.clubs,
    state.nations,
    state.filters
  ])
  const mayClearFilters = useMayClearFilters()

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search..."
              value={filters.searchQuery}
              onChange={(e) => updateFilters({ searchQuery: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Club</InputLabel>
              <Select
                label="Club"
                value={filters.clubId}
                onChange={(e) =>
                  updateFilters({ clubId: e.target.value as string })
                }
              >
                {clubs.map((club, index) => (
                  <MenuItem key={index} value={club.id}>
                    {club.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Nation</InputLabel>
              <Select
                label="Nation"
                value={filters.nation}
                onChange={(e) =>
                  updateFilters({ nation: e.target.value as string })
                }
              >
                {nations.map((nation, index) => (
                  <MenuItem key={index} value={nation}>
                    {nation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Position</InputLabel>
              <Select
                label="Position"
                // "or string" is used because MUI select loses connection with the value when the value is undefined
                value={filters.role || ''}
                onChange={(e) =>
                  updateFilters({ role: e.target.value as PlayerRoleModel })
                }
              >
                <ListSubheader>Position groups</ListSubheader>
                {roles
                  .filter((role) => role.length > 2)
                  .map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                <ListSubheader>Positions</ListSubheader>
                {roles
                  .filter((role) => role.length <= 2)
                  .map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {role}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={!mayClearFilters}
              onClick={clearFilters}
            >
              Clear filters
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
