import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import { Loader } from '@root/components/loader'
import { PlayerPerformances } from '@root/components/player-performances'
import { ClubHeader } from './components/club-header'
import {
  fetchMatchDetail,
  resetMatchDetail
} from './store/match-detail-actions'
import { useClubPlayerPerformances } from './store/match-detail-selectors'
import { useMatchDetailStore } from './store/match-detail-store'

export const MatchDetail: FC = () => {
  const matchDetail = useMatchDetailStore((state) => state.matchDetail)
  const homePlayerPerformances = useClubPlayerPerformances('home')
  const awayPlayerPerformances = useClubPlayerPerformances('away')
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    fetchMatchDetail(id)

    return resetMatchDetail
  }, [id])

  if (!matchDetail) {
    return <Loader />
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              <Grid container spacing={2} justify="center">
                <Grid item>{matchDetail.homeClub.name}</Grid>
                <Grid item>
                  {matchDetail.homeClub.score}-{matchDetail.awayClub.score}
                </Grid>
                <Grid item>{matchDetail.awayClub.name}</Grid>
                <Grid item>(Round {matchDetail.round})</Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ClubHeader
              id={matchDetail.homeClub.id}
              name={matchDetail.homeClub.name}
            />
          </Grid>
          <Grid item xs={12}>
            <PlayerPerformances playerPerformances={homePlayerPerformances} />
          </Grid>
          <Grid item xs={12}>
            <ClubHeader
              id={matchDetail.awayClub.id}
              name={matchDetail.awayClub.name}
            />
          </Grid>
          <Grid item xs={12}>
            <PlayerPerformances playerPerformances={awayPlayerPerformances} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
