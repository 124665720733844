import { endpoints } from '@root/transport/http/endpoints'
import { useAppStore } from './app-store'

const { getState, setState } = useAppStore

export const fetchUsers = async () => {
  const users = await endpoints.getUsers.dispatch()

  setState({
    users,
    selectedUserId: users[0].id
  })
}

export const switchUser = async (userId: string) =>
  setState({
    selectedUserId: userId
  })

export const saveUser = () => {
  const { users, selectedUserId } = getState()

  setState({
    users: users.map((user) =>
      user.id === selectedUserId ? { ...user, hasTeamSetup: true } : user
    )
  })
}
