import create from 'zustand'
import { MatchModel } from '@shared/models/match'

export interface MatchesState {
  rounds: Array<number>
  selectedRound: number
  matches: Array<MatchModel>
}

export const initialState: MatchesState = {
  rounds: [0],
  selectedRound: 0,
  matches: []
}

export const useMatchesStore = create<MatchesState>(() => initialState)
