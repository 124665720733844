"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rolePositionMap = exports.playerRoles = exports.substitutePositions = void 0;
const playerRolesRecord = {
    GK: undefined,
    CB: undefined,
    LB: undefined,
    RB: undefined,
    CM: undefined,
    AM: undefined,
    LW: undefined,
    RW: undefined,
    FW: undefined
};
exports.substitutePositions = [
    'SUB1',
    'SUB2',
    'SUB3',
    'SUB4',
    'SUB5',
    'SUB6',
    'SUB7'
];
exports.playerRoles = Object.keys(playerRolesRecord);
exports.rolePositionMap = {
    GK: ['GK'],
    CB: ['LCB', 'CB', 'RCB'],
    LB: ['LB'],
    RB: ['RB'],
    CM: ['LCM', 'CM', 'RCM'],
    AM: ['LAM', 'AM', 'RAM'],
    LW: ['LW'],
    RW: ['RW'],
    FW: ['LFW', 'FW', 'RFW'],
    SUB: exports.substitutePositions
};
