import { format } from 'date-fns'
import { onlyUnique } from '@shared/helpers/only-unique'
import { useMatchesStore } from './matches-store'

export const useGroupedMatches = () => {
  const matches = useMatchesStore((state) => state.matches)

  return matches
    .map((match) => match.date)
    .filter(onlyUnique)
    .map((date) => ({
      date,
      day: format(new Date(date), 'EEEE'),
      matches: matches.filter((match) => match.date === date)
    }))
}
