import create from 'zustand'
import { MatchDetailModel } from '@shared/models/match-detail'

export interface MatchDetailState {
  matchDetail: MatchDetailModel | undefined
}

export const initialState: MatchDetailState = {
  matchDetail: undefined
}

export const useMatchDetailStore = create<MatchDetailState>(() => initialState)
