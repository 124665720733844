import create from 'zustand'
import { UserModel } from '@shared/models/user'

export interface AppState {
  users: Array<UserModel>
  selectedUserId: string | undefined
}

export const initialState: AppState = {
  users: [],
  selectedUserId: undefined
}

export const useAppStore = create<AppState>(() => initialState)
