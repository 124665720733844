import { endpoints } from '@root/transport/http/endpoints'
import { initialState, useClubDetailStore } from './club-detail-store'

const { setState } = useClubDetailStore

export const resetClubDetail = () => setState({ ...initialState })

export const fetchClubDetail = async (id: string) => {
  setState({
    clubDetail: await endpoints.getClubDetail.dispatch(id)
  })
}
