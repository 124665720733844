import axios, { AxiosRequestConfig } from 'axios'
import { ClientEndpoint, RequestMethod } from '@shared/transport/http/endpoints'
import { getError } from '@shared/transport/http/errors'

const baseUrl = process.env.REACT_APP_SERVER_ADDRESS
const { log } = console
log(process.env)

export interface Options<P> extends AxiosRequestConfig {
  data?: P | FormData
}

export function getRoute(prototype: Function) {
  return Reflect.getMetadata('route', prototype)
}

export function getMethod(prototype: Function) {
  return Reflect.getMetadata('method', prototype)
}

export async function dispatch<P>(payload: P) {
  return request(getRoute(this), getMethod(this), payload)
}

export const request = async <P>(
  route: string,
  method: RequestMethod,
  payload: P,
  options: Options<P> = {}
) => {
  try {
    const response = await axios.request({
      method,
      url: `${baseUrl}${route}`,
      data: method !== 'get' && { payload },
      params: method === 'get' && { payload: JSON.stringify(payload) },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('code')}`
      },
      ...options
    })

    return response.data
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status) {
      throw new (getError(e.response.status))()
    }

    throw e
  }
}

export const getRouteUrl = <P extends {}>(
  endpoint: ClientEndpoint<any, 'get', P, any>,
  payload: P
) =>
  `${baseUrl}${Reflect.getMetadata('route', endpoint)}${
    payload && `?payload=${JSON.stringify(payload)}`
  }`
