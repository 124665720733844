import { endpoints } from '@root/transport/http/endpoints'
import { initialState, usePlayerDetailStore } from './player-detail-store'

const { setState } = usePlayerDetailStore

export const resetPlayerDetail = () => setState({ ...initialState })

export const fetchPlayerDetail = async (id: string) => {
  setState({
    playerDetail: await endpoints.getPlayerDetail.dispatch(id)
  })
}
