"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statCategories = void 0;
exports.statCategories = {
    regularGoals: 0,
    goalsOutsideBox: 0,
    penaltyGoals: 0,
    penaltiesMissed: 0,
    ownGoals: 0,
    assists: 0,
    shotsOnTarget: 0,
    shotsOnWoodwork: 0,
    keyPasses: 0,
    successfulCrosses: 0,
    longBalls: 0,
    completedDribbles: 0,
    foulsWon: 0,
    penaltiesWon: 0,
    penaltiesConceded: 0,
    tacklesWon: 0,
    interceptions: 0,
    clearances: 0,
    blockedShots: 0,
    aerialDuelsWon: 0,
    fouls: 0,
    yellowCards: 0,
    redCards: 0,
    cleanSheets: 0,
    divingSaves: 0,
    regularSaves: 0,
    penaltiesSaved: 0,
    highClaims: 0,
    punches: 0,
    throws: 0,
    goalsAgainst: 0
};
