import { Box, Button, Typography } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  id: string
  name: string
}

export const ClubHeader: FC<Props> = ({ id, name }) => (
  <Box pt={2} display="flex" justifyContent="space-between">
    <Typography variant="h6">{name}</Typography>
    <Button component={Link} to={`/clubs/${id}`} endIcon={<NavigateNext />}>
      Club Details
    </Button>
  </Box>
)
