"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorCode = exports.getError = exports.NotImplementedError = exports.InternalServerError = exports.ConflictError = exports.NotFoundError = exports.ForbiddenError = exports.UnauthorizedError = exports.BadRequestError = exports.BaseError = void 0;
class BaseError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}
exports.BaseError = BaseError;
class BadRequestError extends BaseError {
}
exports.BadRequestError = BadRequestError;
class UnauthorizedError extends BaseError {
}
exports.UnauthorizedError = UnauthorizedError;
class ForbiddenError extends BaseError {
}
exports.ForbiddenError = ForbiddenError;
class NotFoundError extends BaseError {
}
exports.NotFoundError = NotFoundError;
class ConflictError extends BaseError {
}
exports.ConflictError = ConflictError;
class InternalServerError extends BaseError {
}
exports.InternalServerError = InternalServerError;
class NotImplementedError extends BaseError {
}
exports.NotImplementedError = NotImplementedError;
const errorCodes = [
    { error: BadRequestError, code: 400 },
    { error: UnauthorizedError, code: 401 },
    { error: ForbiddenError, code: 403 },
    { error: NotFoundError, code: 404 },
    { error: ConflictError, code: 409 },
    { error: InternalServerError, code: 500 },
    { error: NotImplementedError, code: 501 }
];
const getError = (code) => {
    const errorCode = errorCodes.find((errorCode) => errorCode.code === code);
    if (errorCode) {
        return errorCode.error;
    }
    throw new Error(`Could not find error for error code ${code}`);
};
exports.getError = getError;
const getErrorCode = (error) => {
    const errorCode = errorCodes.find((errorCode) => error instanceof errorCode.error || errorCode.error === error);
    if (errorCode) {
        return errorCode.code;
    }
    throw error;
};
exports.getErrorCode = getErrorCode;
