import { Box, Grid } from '@material-ui/core'
import { FC, useEffect } from 'react'
import { Loader } from '@root/components/loader'
import { useUser } from '@root/store/app-selectors'
import { FormationPlayers } from './components/formation-players'
import { Matches } from './components/matches'
import { Navigation } from './components/navigation'
import { PlayerDialog } from './components/player-dialog'
import { Substitutes } from './components/substitutes'
import { fetchMyTeam, resetViewableTeam } from './store/view-team-actions'
import { useViewTeamStore } from './store/view-team-store'

export const ViewTeam: FC = () => {
  const fetching = useViewTeamStore((state) => state.fetching)
  const user = useUser()

  useEffect(() => {
    setTimeout(fetchMyTeam)

    return resetViewableTeam
  }, [user])

  if (fetching) {
    return <Loader />
  }

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Navigation />
            </Grid>
            <Grid item xs={12}>
              <FormationPlayers />
            </Grid>
            <Grid item xs={12}>
              <Substitutes />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Matches />
        </Grid>
      </Grid>
      <PlayerDialog />
    </Box>
  )
}
