import { Box, Grid } from '@material-ui/core'
import { FC, useEffect } from 'react'
import { ConfirmDialog } from '@root/components/confirm-dialog'
import { Loader } from '@root/components/loader'
import { useUser } from '@root/store/app-selectors'
import { AvailablePlayers } from './components/available-players'
import { Filter } from './components/filter'
import { FormationPlayers } from './components/formation-players'
import { Navigation } from './components/navigation'
import { Substitutes } from './components/substitutes'
import {
  closeDialog,
  fetchEditableTeam,
  resetEditableTeam,
  resetTeam
} from './store/edit-team-actions'
import { useEditTeamStore } from './store/edit-team-store'
import { toggleEditing } from '../../store/my-team-actions'

export const EditTeam: FC = () => {
  const user = useUser()
  const [viewState, activeDialog] = useEditTeamStore((state) => [
    state.viewState,
    state.activeDialog
  ])

  useEffect(() => {
    setTimeout(fetchEditableTeam)

    return resetEditableTeam
  }, [user])

  if (viewState === 'fetching') {
    return <Loader />
  }

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Navigation />
            </Grid>
            <Grid item xs={12}>
              <FormationPlayers />
            </Grid>
            <Grid item xs={12}>
              <Substitutes />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Filter />
            </Grid>
            <Grid item xs={12}>
              <AvailablePlayers />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={activeDialog === 'confirmReset'}
        disabled={viewState === 'resetting'}
        title="Reset"
        contentText="Are you sure you want to reset? You will revert all your changes you have made this round."
        close={closeDialog}
        confirm={resetTeam}
      />
      <ConfirmDialog
        open={activeDialog === 'confirmCancel'}
        title="Cancel"
        contentText="Are you sure you want to cancel? You will lose all the changes you have made."
        close={closeDialog}
        confirm={toggleEditing}
      />
      <ConfirmDialog
        open={activeDialog === 'notEnoughTransfers'}
        title="Not enough transfers"
        contentText="You don't have enough transfers to complete this action. Either wait for the next round to receive new transfers, or use a wildcard to proceed."
        close={closeDialog}
      />
    </Box>
  )
}
