import { ClientEndpointCollection } from '@shared/transport/http/endpoints'
import { myTeamMockEndpoints } from './my-team/my-team'
import { mockRequest } from '../mock-request'

export const mockEndpoints: ClientEndpointCollection<AbortSignal> = {
  getMatches: {
    method: 'get',
    dispatch: async () => ({
      rounds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 32, 37],
      defaultRound: 37,
      matches: [
        {
          round: 1,
          id: 'abc',
          date: '2018-01-01',
          time: '18:00',
          homeClub: {
            id: '1',
            name: 'Fiorentina',
            score: 1
          },
          awayClub: {
            id: '2',
            name: 'Torino',
            score: 0
          }
        },
        {
          round: 1,
          id: 'def',
          date: '2018-01-01',
          time: '21:00',
          homeClub: {
            id: '1',
            name: 'Juventus',
            score: 3
          },
          awayClub: {
            id: '2',
            name: 'Milan',
            score: 1
          }
        },
        {
          round: 1,
          id: 'ghi',
          date: '2018-01-02',
          time: '21:00',
          homeClub: {
            id: '1',
            name: 'Napoli',
            score: 0
          },
          awayClub: {
            id: '2',
            name: 'Chievo',
            score: 0
          }
        }
      ]
    })
  },
  getMatchDetail: {
    method: 'get',
    dispatch: async () => ({
      id: 'abc',
      round: 7,
      date: '2018-01-01',
      time: '18:00',
      homeClub: {
        id: 'd609edc0',
        name: 'Inter',
        score: 1
      },
      awayClub: {
        id: 'e2befd26',
        name: 'Sassuolo',
        score: 0
      },
      playerPerformances: [
        {
          id: '6e058f06',
          clubId: 'e2befd26',
          name: 'Giacomo Raspadori',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1
        },
        {
          id: '570bb4b9',
          clubId: 'e2befd26',
          name: '   Hamed Junior Traorè',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 1,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25
        },
        {
          id: 'fe012aca',
          clubId: 'e2befd26',
          name: 'Jeremie Boga',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 7,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 3,
            blockedShots: 0,
            foulsWon: 3,
            aerialDuelsWon: 1,
            longBalls: 2,
            clearances: 1,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 18.75
        },
        {
          id: '53a0e358',
          clubId: 'e2befd26',
          name: 'Domenico Berardi',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 2,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 4,
            blockedShots: 0,
            foulsWon: 3,
            aerialDuelsWon: 1,
            longBalls: 2,
            clearances: 1,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 11.25
        },
        {
          id: 'eccbe56a',
          clubId: 'e2befd26',
          name: 'Filip Đuričić',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 1,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 2,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 2,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 7
        },
        {
          id: 'ba1e04b3',
          clubId: 'e2befd26',
          name: '   Pedro Obiang',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1.75
        },
        {
          id: 'a8a874f1',
          clubId: 'e2befd26',
          name: 'Manuel Locatelli',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 4,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 3,
            interceptions: 3,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 3,
            longBalls: 18,
            clearances: 1,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 16.25
        },
        {
          id: '20094685',
          clubId: 'e2befd26',
          name: 'Maxime Lopez',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25
        },
        {
          id: 'afe31d55',
          clubId: 'e2befd26',
          name: '   Nicolás Schiappacasse',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 0
        },
        {
          id: '10e7f486',
          clubId: 'e2befd26',
          name: 'Rogério',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 3,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 0,
            longBalls: 2,
            clearances: 0,
            fouls: 4,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1
        },
        {
          id: '8650af85',
          clubId: 'e2befd26',
          name: 'Gian Marco Ferrari',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 1,
            blockedShots: 2,
            foulsWon: 1,
            aerialDuelsWon: 2,
            longBalls: 10,
            clearances: 1,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 5.25
        },
        {
          id: 'c316607a',
          clubId: 'e2befd26',
          name: 'Vlad Chiricheș',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 4,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 2,
            longBalls: 8,
            clearances: 1,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 1,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25
        },
        {
          id: 'cdf65d12',
          clubId: 'e2befd26',
          name: '   Marlon',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 1,
            longBalls: 5,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 3.75
        },
        {
          id: 'a96e5dc0',
          clubId: 'e2befd26',
          name: 'Jeremy Toljan',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 1,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25
        },
        {
          id: '1295552e',
          clubId: 'e2befd26',
          name: '   Mert Müldür',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 1,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 6.25
        },
        {
          id: '8cabdc1e',
          clubId: 'e2befd26',
          name: 'Andrea Consigli',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 5,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 2,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 2,
            goalsAgainst: 3
          },
          points: 0.25
        },
        {
          id: '2c0f8e9e',
          clubId: 'd609edc0',
          name: 'Alexis Sánchez',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 1,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 2,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 4,
            aerialDuelsWon: 1,
            longBalls: 5,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 14.25
        },
        {
          id: 'be927d03',
          clubId: 'd609edc0',
          name: '   Ashley Young',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 1,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: -0.75
        },
        {
          id: 'f7036e1c',
          clubId: 'd609edc0',
          name: 'Lautaro Martínez',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 1,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 2,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 1,
            tacklesWon: 1,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 11.25
        },
        {
          id: '5eae500a',
          clubId: 'd609edc0',
          name: '   Romelu Lukaku',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 0
        },
        {
          id: 'ce30bfc8',
          clubId: 'd609edc0',
          name: 'Roberto Gagliardini',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 1,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 2,
            shotsOnTarget: 0,
            tacklesWon: 2,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 1,
            longBalls: 3,
            clearances: 2,
            fouls: 3,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 10.75
        },
        {
          id: '67cdbc0e',
          clubId: 'd609edc0',
          name: 'Arturo Vidal',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 4,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 2,
            longBalls: 8,
            clearances: 3,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 8.75
        },
        {
          id: '6928979a',
          clubId: 'd609edc0',
          name: 'Nicolò Barella',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 3,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 1,
            longBalls: 6,
            clearances: 2,
            fouls: 3,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 5.5
        },
        {
          id: 'b70bde8f',
          clubId: 'd609edc0',
          name: '   Stefano Sensi',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 0
        },
        {
          id: '6fe90922',
          clubId: 'd609edc0',
          name: 'Ivan Perišić',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 1,
            completedDribbles: 0,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 1,
            longBalls: 6,
            clearances: 4,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 3
        },
        {
          id: '980522ec',
          clubId: 'd609edc0',
          name: '   Christian Eriksen',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1.5
        },
        {
          id: '96e0490d',
          clubId: 'd609edc0',
          name: 'Matteo Darmian',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 1,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 2,
            successfulCrosses: 2,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 4,
            interceptions: 2,
            blockedShots: 1,
            foulsWon: 3,
            aerialDuelsWon: 1,
            longBalls: 5,
            clearances: 4,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 21.25
        },
        {
          id: 'e42d61c7',
          clubId: 'd609edc0',
          name: '   Achraf Hakimi',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 0
        },
        {
          id: '75b86fb3',
          clubId: 'd609edc0',
          name: 'Alessandro Bastoni',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 0,
            blockedShots: 1,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 8,
            clearances: 2,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 3
        },
        {
          id: 'd0d8dd44',
          clubId: 'd609edc0',
          name: 'Stefan de Vrij',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 2,
            blockedShots: 2,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 8,
            clearances: 7,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 8.25
        },
        {
          id: '30f5ffa9',
          clubId: 'd609edc0',
          name: 'Milan Škriniar',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 2,
            blockedShots: 1,
            foulsWon: 2,
            aerialDuelsWon: 3,
            longBalls: 5,
            clearances: 8,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 8.25
        },
        {
          id: '74419746',
          clubId: 'd609edc0',
          name: 'Samir Handanović',
          squadNumber: 0,
          position: '-',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 8,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 1,
            regularSaves: 3,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 5,
            goalsAgainst: 0
          },
          points: 11.75
        }
      ]
    })
  },
  getClubs: {
    method: 'get',
    dispatch: async () => [
      { id: '1', name: 'Juventus' },
      { id: '2', name: 'Inter' },
      { id: '3', name: 'Roma' },
      { id: '4', name: 'Hellas Verona' },
      { id: '5', name: 'Napoli' },
      { id: '6', name: 'Chievo' },
      { id: '7', name: 'Atalanta' },
      { id: '8', name: 'Genoa' }
    ]
  },
  getClubDetail: {
    method: 'get',
    dispatch: async () => ({
      id: 'e2befd26',
      name: 'Sassuolo',
      players: [
        {
          id: '6e058f06',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Giacomo Raspadori',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1,
          totalPoints: 8,
          price: 5
        },
        {
          id: '570bb4b9',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: '   Hamed Junior Traorè',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 1,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        },
        {
          id: 'fe012aca',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Jeremie Boga',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 7,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 3,
            blockedShots: 0,
            foulsWon: 3,
            aerialDuelsWon: 1,
            longBalls: 2,
            clearances: 1,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 18.75,
          totalPoints: 150,
          price: 18
        },
        {
          id: '53a0e358',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Domenico Berardi',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 2,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 4,
            blockedShots: 0,
            foulsWon: 3,
            aerialDuelsWon: 1,
            longBalls: 2,
            clearances: 1,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 11.25,
          totalPoints: 90,
          price: 11
        },
        {
          id: 'eccbe56a',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Filip Đuričić',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 1,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 2,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 2,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 7,
          totalPoints: 56,
          price: 7
        },
        {
          id: 'ba1e04b3',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: '   Pedro Obiang',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1.75,
          totalPoints: 14,
          price: 5
        },
        {
          id: 'a8a874f1',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Manuel Locatelli',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 4,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 3,
            interceptions: 3,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 3,
            longBalls: 18,
            clearances: 1,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 16.25,
          totalPoints: 130,
          price: 15
        },
        {
          id: '20094685',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Maxime Lopez',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        },
        {
          id: 'afe31d55',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: '   Nicolás Schiappacasse',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 0,
          totalPoints: 0,
          price: 5
        },
        {
          id: '10e7f486',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Rogério',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 3,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 0,
            longBalls: 2,
            clearances: 0,
            fouls: 4,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1,
          totalPoints: 8,
          price: 5
        },
        {
          id: '8650af85',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Gian Marco Ferrari',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 1,
            blockedShots: 2,
            foulsWon: 1,
            aerialDuelsWon: 2,
            longBalls: 10,
            clearances: 1,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 5.25,
          totalPoints: 42,
          price: 5
        },
        {
          id: 'c316607a',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Vlad Chiricheș',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 4,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 2,
            longBalls: 8,
            clearances: 1,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 1,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        },
        {
          id: 'cdf65d12',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: '   Marlon',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 1,
            longBalls: 5,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 3.75,
          totalPoints: 30,
          price: 5
        },
        {
          id: 'a96e5dc0',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Jeremy Toljan',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 1,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        },
        {
          id: '1295552e',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: '   Mert Müldür',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 1,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 6.25,
          totalPoints: 50,
          price: 6
        },
        {
          id: '8cabdc1e',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Andrea Consigli',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 5,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 2,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 2,
            goalsAgainst: 3
          },
          points: 0.25,
          totalPoints: 2,
          price: 5
        }
      ]
    })
  },
  getPlayerDetail: {
    method: 'get',
    dispatch: async () => ({
      id: 'e2befd26',
      name: 'Domenico Berardi',
      positions: ['AM', 'RW', 'RWB'],
      totalPoints: 178.25,
      price: 17.8,
      performances: [
        {
          id: '6e058f06',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Genoa',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1,
          totalPoints: 8,
          price: 5
        },
        {
          id: '570bb4b9',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Juventus',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 1,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        },
        {
          id: 'fe012aca',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Inter',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 7,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 3,
            blockedShots: 0,
            foulsWon: 3,
            aerialDuelsWon: 1,
            longBalls: 2,
            clearances: 1,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 18.75,
          totalPoints: 150,
          price: 18
        },
        {
          id: '53a0e358',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Crotone',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 2,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 4,
            blockedShots: 0,
            foulsWon: 3,
            aerialDuelsWon: 1,
            longBalls: 2,
            clearances: 1,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 11.25,
          totalPoints: 90,
          price: 11
        },
        {
          id: 'eccbe56a',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Hellas Verona',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 1,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 2,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 2,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 7,
          totalPoints: 56,
          price: 7
        },
        {
          id: 'ba1e04b3',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Atalanta',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1.75,
          totalPoints: 14,
          price: 5
        },
        {
          id: 'a8a874f1',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Milan',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 4,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 3,
            interceptions: 3,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 3,
            longBalls: 18,
            clearances: 1,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 16.25,
          totalPoints: 130,
          price: 15
        },
        {
          id: '20094685',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Torino',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 1,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 2,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 3,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        },
        {
          id: 'afe31d55',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Roma',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 0,
            longBalls: 0,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 0,
          totalPoints: 0,
          price: 5
        },
        {
          id: '10e7f486',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Lazio',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 3,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 0,
            longBalls: 2,
            clearances: 0,
            fouls: 4,
            penaltiesMissed: 0,
            yellowCards: 1,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 1,
          totalPoints: 8,
          price: 5
        },
        {
          id: '8650af85',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Chievo',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 1,
            blockedShots: 2,
            foulsWon: 1,
            aerialDuelsWon: 2,
            longBalls: 10,
            clearances: 1,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 5.25,
          totalPoints: 42,
          price: 5
        },
        {
          id: 'c316607a',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Fiorentina',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 0,
            interceptions: 4,
            blockedShots: 0,
            foulsWon: 2,
            aerialDuelsWon: 2,
            longBalls: 8,
            clearances: 1,
            fouls: 2,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 1,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        },
        {
          id: 'cdf65d12',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Napoli',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 0,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 0,
            tacklesWon: 1,
            interceptions: 1,
            blockedShots: 0,
            foulsWon: 0,
            aerialDuelsWon: 1,
            longBalls: 5,
            clearances: 0,
            fouls: 0,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 3.75,
          totalPoints: 30,
          price: 5
        },
        {
          id: 'a96e5dc0',
          clubId: 'e2befd26',
          squadNumber: 0,
          position: '-',
          name: 'Bologna',
          stats: {
            goalsOutsideBox: 0,
            regularGoals: 0,
            assists: 0,
            penaltyGoals: 0,
            penaltiesWon: 0,
            shotsOnWoodwork: 0,
            keyPasses: 1,
            successfulCrosses: 0,
            completedDribbles: 0,
            shotsOnTarget: 1,
            tacklesWon: 0,
            interceptions: 0,
            blockedShots: 0,
            foulsWon: 1,
            aerialDuelsWon: 0,
            longBalls: 1,
            clearances: 0,
            fouls: 1,
            penaltiesMissed: 0,
            yellowCards: 0,
            penaltiesConceded: 0,
            ownGoals: 0,
            redCards: 0,
            penaltiesSaved: 0,
            cleanSheets: 0,
            regularSaves: 0,
            punches: 0,
            divingSaves: 0,
            highClaims: 0,
            throws: 0,
            goalsAgainst: 0
          },
          points: 2.25,
          totalPoints: 18,
          price: 5
        }
      ]
    })
  },
  getLeague: {
    method: 'get',
    dispatch: () =>
      mockRequest([
        { user: 'User 1', rounds: [67, 48, 115.25] },
        { user: 'User 2', rounds: [122, 148.5, 35] },
        { user: 'User 3', rounds: [88, 95.75, 117] }
      ])
  },
  getUsers: {
    method: 'get',
    dispatch: () =>
      mockRequest([
        { id: '1', name: 'User 1', hasTeamSetup: true },
        { id: '2', name: 'User 2', hasTeamSetup: true },
        { id: '3', name: 'User 3', hasTeamSetup: false }
      ])
  },
  myTeam: myTeamMockEndpoints
}
