import { ClientEndpointCollection } from '@shared/transport/http/endpoints'
import { mockEndpoints } from './mock-endpoints/mock-endpoints'
import { dispatch } from './request'

export const endpoints: ClientEndpointCollection<AbortSignal> =
  process.env.REACT_APP_MOCK === 'true'
    ? mockEndpoints
    : {
        getMatches: { method: 'get', dispatch },
        getMatchDetail: { method: 'get', dispatch },
        getClubs: { method: 'get', dispatch },
        getClubDetail: { method: 'get', dispatch },
        getPlayerDetail: { method: 'get', dispatch },
        getLeague: { method: 'get', dispatch },
        getUsers: { method: 'get', dispatch },
        myTeam: {
          getMyTeam: { method: 'get', dispatch },
          getMyTeamByRound: { method: 'get', dispatch },
          getEditableTeam: { method: 'get', dispatch },
          getAvailablePlayers: { method: 'get', dispatch },
          resetTeam: { method: 'put', dispatch },
          saveTeam: { method: 'put', dispatch }
        }
      }
