import 'reflect-metadata'
import { FC } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router'
import { Layout } from './layout/layout'
import { ClubDetail } from './pages/club-detail/club-detail'
import { Clubs } from './pages/clubs/clubs'
import { Leagues } from './pages/leagues/leagues'
import { MatchDetail } from './pages/match-detail/match-detail'
import { Matches } from './pages/matches/matches'
import { MyTeam } from './pages/my-team/my-team'
import { PlayerDetail } from './pages/player-detail/player-detail'
import { endpoints } from './transport/http/endpoints'
import { setupEndpoints } from './transport/http/setup'
import { getHistory } from './utils/history'

// FIXME
setupEndpoints(endpoints as any)

export const App: FC = () => (
  <Router history={getHistory()}>
    <Layout>
      <Switch>
        <Route exact path="/">
          <Redirect to="/matches" />
        </Route>
        <Route exact path="/matches">
          <Matches />
        </Route>
        <Route exact path="/matches/:id">
          <MatchDetail />
        </Route>
        <Route exact path="/clubs">
          <Clubs />
        </Route>
        <Route exact path="/clubs/:id">
          <ClubDetail />
        </Route>
        <Route exact path="/leagues">
          <Leagues />
        </Route>
        <Route exact path="/players/:id">
          <PlayerDetail />
        </Route>
        <Route exact path="/my-team">
          <MyTeam />
        </Route>
      </Switch>
    </Layout>
  </Router>
)
