import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import clsx from 'clsx'
import { FC } from 'react'
import { showPlayerInfo } from '@root/pages/my-team/store/my-team-actions'
import {
  showMoreAvailablePlayers,
  transferAvailablePlayer
} from '../store/edit-team-actions'
import {
  useAvailablePlayers,
  useMayShowMorePlayers
} from '../store/edit-team-selectors'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 735,
    overflowY: 'scroll'
  },
  playerRowClickable: {
    cursor: 'pointer'
  },
  playerRowDisabled: {
    '& .MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.4)'
    },
    cursor: 'default'
  },
  showMorePlayersButton: {
    marginTop: theme.spacing(1.25)
  }
}))

export const AvailablePlayers: FC = () => {
  const availablePlayers = useAvailablePlayers()
  const mayShowMorePlayers = useMayShowMorePlayers()
  const classes = useStyles()

  return (
    <>
      <Paper className={classes.root}>
        <Table size="small">
          <TableBody>
            {availablePlayers.map((player, index) => (
              <TableRow
                key={index}
                hover={player.mayTransfer && player.mayTransfer}
                className={clsx(
                  player.mayTransfer && classes.playerRowClickable,
                  player.unavailable && classes.playerRowDisabled
                )}
                onClick={() =>
                  player.mayTransfer && transferAvailablePlayer(player)
                }
              >
                <TableCell padding="none" width="10%">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      showPlayerInfo(player)
                    }}
                  >
                    <Info />
                  </IconButton>
                </TableCell>
                <TableCell padding="none" width="35%">
                  {player.name}
                </TableCell>
                <TableCell padding="none" width="20%">
                  € {player.price}M
                </TableCell>
                <TableCell padding="none" width="35%" align="right">
                  <Box mr={2}>{player.roles.join(', ')}</Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        disabled={!mayShowMorePlayers}
        className={classes.showMorePlayersButton}
        onClick={showMoreAvailablePlayers}
      >
        Show more players
      </Button>
    </>
  )
}
